/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import { MainContentWrapper, PieChart } from '@CommonScene';
import Card from '@ParticipantsManagementScene/scenes/Dashboard/components/Card';

// Material UI
import { Grid } from '@material-ui/core';

// Utils
import { compose } from '@Utils';
import { groupBy } from 'lodash';

export class ParticipationDashboard extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    AuthStore: PropTypes.object.isRequired,
    FilterStore: PropTypes.object.isRequired,
    ReportingStore: PropTypes.object.isRequired
  };

  getReport() {
    const { ReportingStore } = this.props;

    return ReportingStore.getReport(ReportingStore.reportId, {
      roles: [],
      statuses: []
    }).then(() => {
      const {
        ReportingStore: { setFilters }
      } = this.props;
      setFilters(['status', 'role']);
    });
  }

  componentDidMount() {
    const { ReportingStore } = this.props;

    ReportingStore.getReports().then(() => {
      const participationReport = ReportingStore.reports.find(
        report => report.name === 'Participation Report'
      );
      ReportingStore.setReportId(participationReport.id);
      this.getReport();
    });
  }

  componentWillUnmount() {
    this.props.FilterStore.reset();
  }

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('can.participantsManagement.participationDashboard.title')
    };
  }

  getFilterOptions() {
    return {
      applyHandler: this.applyFilter
    };
  }

  applyFilter = () => {
    const {
      FilterStore: { selectedFilters, resendRequestForFilters },
      ReportingStore: { getReport, reports }
    } = this.props;
    const participationReport = reports.find(
      report => report.name === 'Participation Report'
    );

    if (resendRequestForFilters) {
      this.getReport().then(() => {
        getReport(participationReport.id, {
          statuses: selectedFilters.status,
          roles: selectedFilters.role
        });
      });
    }

    return getReport(participationReport.id, {
      statuses: selectedFilters.status,
      roles: selectedFilters.role
    });
  };

  render() {
    const {
      ReportingStore: {
        report: { aggregation, details }
      }
    } = this.props;

    // TODO: Refactor code below

    const groupedDetails = groupBy(details, 'status');
    const groupedDetailsKeys = Object.keys(groupedDetails);

    const getPieChartSortingIndexes = key => {
      const indexes = {
        SUSPENDED: {
          index: 4,
          color: '#FE4848'
        },
        ASSIGNED_WALLET: {
          index: 1,
          color: '#6FC960'
        },
        INVITATION_EXPIRED: {
          index: 5,
          color: '#FE4848'
        },
        INVITATION_PENDING: {
          index: 2,
          color: '#FFA91E'
        },
        CREATED: {
          index: 3,
          color: '#9B51E0'
        }
      };
      return indexes[key] || {};
    };

    const getCardsSortingIndexes = key => {
      const indexes = {
        SUSPENDED: {
          index: 2,
          color: '#FE4848'
        },
        ASSIGNED_WALLET: {
          index: 1,
          color: '#6FC960'
        },
        INVITATION_EXPIRED: {
          index: 4,
          color: '#FE4848'
        },
        INVITATION_PENDING: {
          index: 3,
          color: '#FFA91E'
        },
        CREATED: {
          index: 5,
          color: '#9B51E0'
        }
      };
      return indexes[key] || {};
    };

    const pieChartIndexedData = aggregation.map(item => {
      return {
        ...item,
        index: getPieChartSortingIndexes(item.status).index,
        color: getPieChartSortingIndexes(item.status).color
      };
    });

    const cardsIndexedData = groupedDetailsKeys.map(status => {
      return {
        status,
        index: getCardsSortingIndexes(status).index,
        color: getCardsSortingIndexes(status).color
      };
    });

    const getSortedData = data =>
      data.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      });

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        filterOptions={this.getFilterOptions()}
      >
        <PieChart
          data={getSortedData(pieChartIndexedData)}
          nameKey="status"
          valueKey="total"
        />
        <Grid container spacing={2}>
          {getSortedData(cardsIndexedData).map(({ status }) => {
            return (
              <Card
                key={shortid.generate()}
                status={status}
                items={groupedDetails[status]}
              />
            );
          })}
        </Grid>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('AuthStore', 'ReportingStore', 'FilterStore'),
  observer
)(ParticipationDashboard);
