/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';

// Styles
import useStyles from './styles';

const DELETE_KEY = 46;
const BACKSPACE_KEY = 8;

const valueReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.payload];
    case 'remove':
      return state.slice(0, state.length - 1);
    default:
      return state;
  }
};

const CodeInput = ({ blocks, setCode, clearCode }) => {
  const classes = useStyles();
  const [input, setInput] = useState(0);
  const [values, dispatch] = useReducer(valueReducer, []);
  const refs = [];

  const onChange = e => {
    // add values from inputs
    dispatch({ type: 'add', payload: e.target.value });
  };

  const onKeyUpHandler = id => e => {
    // go to next input if don't pressed delete
    const nextId = id + 1;

    if (e.keyCode === DELETE_KEY || e.keyCode === BACKSPACE_KEY) {
      return;
    }

    // Submit form when all inputs filled
    if (nextId === blocks) {
      setCode(values.join(''));
      return;
    }

    setInput(nextId);
  };

  const onKeyDownHandler = id => e => {
    // go to previous input when pressed delete
    if (e.keyCode === DELETE_KEY || e.keyCode === BACKSPACE_KEY) {
      const prevId = id - 1;

      if (prevId < 0) {
        return;
      }

      if (id === blocks - 1 && values[id]) {
        // delete saved value
        clearCode();
        dispatch({ type: 'remove' });
        // live focus on last step
        return;
      }

      dispatch({ type: 'remove' });
      setInput(prevId);
    }
  };

  // set focus on first empty input
  const onClickHandler = () => {
    refs[input].focus();
  };

  // set focus on next/prev input
  useEffect(() => {
    refs[input].focus();
  }, [input]);

  return (
    <Grid container justify="space-between" onClick={onClickHandler}>
      {Array.from(Array(blocks).keys()).map(block => (
        <input
          key={block}
          id={`code-${block}`}
          ref={ref => {
            refs[block] = ref;
          }}
          value={values[block] || ''}
          disabled={input !== block}
          type="text"
          className={classes.input}
          onChange={onChange}
          onKeyUp={onKeyUpHandler(block)}
          onKeyDown={onKeyDownHandler(block)}
          maxLength={1}
        />
      ))}
    </Grid>
  );
};

CodeInput.propTypes = {
  blocks: PropTypes.number,
  clearCode: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired
};

CodeInput.defaultProps = {
  blocks: 6
};

export default CodeInput;
