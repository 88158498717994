/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import i18n from 'i18next';

// Utils
import {
  ParticipantsManagement,
  ConfigurationIcon,
  StatisticsIcon
} from '@Utils/constans/icons';

// Alias Routes
import NoAdminRights from '@CommonScene/noAdminRights';
import NotFoundPage from '@CommonScene/notFoundPage';

// Networks
import NetworksList from '@NetworkScene/scenes/NetworksList/container';
import NetworkView from '@NetworkScene/scenes/ViewNetwork/container';
import NetworkEdit from '@NetworkScene/scenes/EditNetwork/container';
import NetworkRoleEdit from '@NetworkScene/scenes/EditRole/container';

// Roles
import AdminRolesList from '@RolesScene/scenes/AdminRolesList/container';
import UserRolesList from '@RolesScene/scenes/UserRolesList/container';
import AddRole from '@RolesScene/scenes/AddRole/container';
import ViewUserRole from '@RolesScene/scenes/ViewUserRole/container';
import EditRole from '@RolesScene/scenes/EditRole/container';
import TermsAndConditionsView from '@RolesScene/scenes/ViewRole/components/TermsAndConditions/View';
import TermsAndConditionsNew from '@RolesScene/scenes/ViewRole/components/TermsAndConditions/New';

// Groups
import GroupsList from '@GroupsScene/scenes/GroupsList/container';
import ViewGroup from '@GroupsScene/scenes/ViewGroup/container';

// Deposits
import DepositsList from '@DepositsScene/scenes/DepositsList/container';

// Payments
import ServiceView from '@PaymentsScene/scenes/ServiceView/container';
import ServicePayments from '@PaymentsScene/scenes/ServicePayments/container';
import MembersView from '@PaymentsScene/scenes/MembersView/container';
import MemberPayments from '@PaymentsScene/scenes/MemberPayments/container';

// Wallets
import Wallets from '@ServiceWalletScene/scenes/Wallets/container';
import PaymentsAdminService from '@ServiceWalletScene/scenes/Payments/container';
import NewWithdrawal from '@ServiceWalletScene/scenes/Withdrawals/scenes/NewWithdrawal/container';
import WithdrawalsList from '@ServiceWalletScene/scenes/Withdrawals/scenes/WithdrawalsList/container';

// Statistics
import CanTopologyStatistics from '@StatisticsScene/scenes/CanTopologyStatistics/container';
import NodeActivityList from '@StatisticsScene/scenes/NodeActivity/NodeActivityList/container';
import NodeActivityDetails from '@StatisticsScene/scenes/NodeActivity/NodeActivityDetails/container';

// ParticipantsManagement
import ParticipationDashboard from '@ParticipantsManagementScene/scenes/Dashboard/container';
import ParticipantsList from '@ParticipantsManagementScene/scenes/ParticipantsList/container';
import NewParticipant from '@ParticipantsManagementScene/scenes/NewParticipant/container';
import ParticipantDetails from '@ParticipantsManagementScene/scenes/ParticipantDetails/container';

// Utils
import { getMenuItems } from '@Utils/formatting';
import {
  ADMIN_ROLES,
  VIEW_ADMIN_ROLES,
  CREATE_ADMIN_ROLES,
  USER_ROLES,
  VIEW_USER_ROLES,
  CREATE_USER_ROLES,
  CREATE_TERMS_AND_CONDITIONS,
  GENERATE_SUBSET_OF_DATASET,
  INVITATIONS,
  CANCEL_INVITATIONS,
  CREATE_INVITATIONS,
  RESEND_INVITATIONS,
  VIEW_INVITATIONS
} from './permissions';
import { getRolesPath } from './paths';

// Used for Breadcrumbs and Main menu
const rolesSection = 'Roles';
const networkSection = 'Network Participants';
const groupSection = 'Wallet Groups';
const depositsSection = 'Deposits';
const paymentsSection = 'Payments';
const serviceWalletSection = 'Service Wallets';
const statisticsSection = 'CAN Statistics';
const participantsManagementSection = 'Participants';

// TODO: del after implementing new roles
const WM_ADMIN = 'SYSTEM_DECK';
export const PAYMENT_ADMIN = 'PAYMENT_ADMIN_DECK';
export const CAN_SPONSOR = 'CAN_SPONSOR_DECK';
export const CAN_STATISTICS = 'REPORTING_ADMIN';

const RoleRoutes = [
  {
    path: '/user-roles',
    name: 'user_roles',
    title: i18n.t('mwAdmin.sidebar.participantRoles'),
    backLink: i18n.t('routes.backToParticipantRoles'),
    section: rolesSection,
    exact: true,
    icon: '',
    menu: true,
    component: UserRolesList,
    roles: [WM_ADMIN, USER_ROLES, VIEW_USER_ROLES, CREATE_USER_ROLES]
  },
  {
    path: '/user-roles/create',
    name: 'role_create',
    parent: 'user_roles',
    title: i18n.t('mwAdmin.roles.addRole'),
    section: rolesSection,
    exact: true,
    icon: '',
    component: AddRole,
    roles: [WM_ADMIN, USER_ROLES, CREATE_USER_ROLES]
  },
  {
    path: '/user-roles/:role/view',
    getPath: getRolesPath.userRoleView,
    name: 'role_view',
    parent: 'user_roles',
    title: 'Role profile',
    section: rolesSection,
    exact: true,
    icon: '',
    component: ViewUserRole,
    roles: [WM_ADMIN, USER_ROLES, VIEW_USER_ROLES, CREATE_USER_ROLES]
  },
  {
    path: '/user-roles/:role/edit',
    name: 'role_edit',
    parent: 'user_roles',
    title: 'Role profile (Edit)',
    section: rolesSection,
    exact: true,
    icon: '',
    component: EditRole,
    roles: [WM_ADMIN, USER_ROLES, CREATE_USER_ROLES]
  },
  {
    path: '/roles/:roleId/termsAndConditions/:termId/view',
    name: 'role_term_view',
    parent: 'role_view',
    title: 'Terms and Conditions',
    section: rolesSection,
    exact: true,
    icon: '',
    component: TermsAndConditionsView,
    roles: [WM_ADMIN, CREATE_USER_ROLES, USER_ROLES, VIEW_USER_ROLES]
  },
  {
    path: '/roles/:roleId/termsAndConditions/new',
    name: 'role_term_new',
    parent: 'role_view',
    title: 'Terms and Conditions (new)',
    section: rolesSection,
    exact: true,
    icon: '',
    component: TermsAndConditionsNew,
    roles: [
      WM_ADMIN,
      CREATE_USER_ROLES,
      USER_ROLES,
      CREATE_TERMS_AND_CONDITIONS
    ]
  },
  {
    path: '/admin-roles',
    name: 'admin_roles',
    title: i18n.t('mwAdmin.sidebar.adminRoles'),
    section: rolesSection,
    exact: true,
    icon: '',
    menu: true,
    component: AdminRolesList,
    roles: [WM_ADMIN, ADMIN_ROLES, VIEW_ADMIN_ROLES, CREATE_ADMIN_ROLES]
  }
];

const NetworkRoutes = [
  {
    path: '/networks/view/:id',
    name: 'network_view',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfile'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NetworkView,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks/add-role/:userId',
    name: 'network_add_roles',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfileAddingRole'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NewParticipant,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks/edit/:id',
    name: 'network_edit',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfileEditing'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NetworkEdit,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks/:userId/edit-role/:roleId',
    name: 'network_role_edit',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfileRoleEditing'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NetworkRoleEdit,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks',
    name: 'networks',
    title: i18n.t('mwAdmin.sidebar.networkParticipants'),
    section: networkSection,
    exact: true,
    icon: '',
    menu: true,
    component: NetworksList,
    roles: [WM_ADMIN]
  }
];

const GroupRoutes = [
  {
    path: '/groups',
    name: 'groups',
    title: i18n.t('mwAdmin.groups.walletGroups'),
    section: groupSection,
    exact: true,
    icon: '',
    menu: true,
    component: GroupsList,
    roles: [WM_ADMIN]
  },
  {
    path: '/groups/view/:group',
    parent: 'groups',
    name: 'group_view',
    title: 'Wallet Group Details',
    section: groupSection,
    exact: true,
    icon: '',
    component: ViewGroup,
    roles: [WM_ADMIN]
  }
];

const DepositsRoutes = [
  {
    name: 'deposits',
    title: i18n.t('can.sidebar.solveManagement'),
    section: depositsSection,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/deposits',
    name: 'deposits_details',
    title: i18n.t('can.sidebar.deposits'),
    parent: 'deposits',
    section: depositsSection,
    exact: true,
    menu: true,
    component: DepositsList,
    roles: [CAN_SPONSOR]
  }
];

const PaymentsRoutes = [
  {
    name: 'payments',
    title: i18n.t('can.sidebar.payments'),
    section: paymentsSection,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/service',
    name: 'payments_service_view',
    title: i18n.t('can.sidebar.services'),
    parent: 'payments',
    section: paymentsSection,
    exact: true,
    menu: true,
    component: ServiceView,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/service/:service',
    name: 'payments_service',
    title: i18n.t('can.services.transactions.title'),
    parent: 'payments_service_view',
    section: paymentsSection,
    exact: true,
    component: ServicePayments,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/members',
    name: 'payments_members_view',
    title: i18n.t('can.sidebar.members'),
    parent: 'payments',
    section: paymentsSection,
    exact: true,
    menu: true,
    component: MembersView,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/members/:member',
    name: 'payments_members',
    title: i18n.t('can.members.transactions.title'),
    parent: 'payments_members_view',
    section: paymentsSection,
    exact: true,
    component: MemberPayments,
    roles: [CAN_SPONSOR]
  }
];

const ServiceWallets = [
  {
    path: '/wallets',
    name: 'wallets',
    section: serviceWalletSection,
    title: 'Service Wallets',
    exact: true,
    menu: true,
    component: Wallets,
    roles: [PAYMENT_ADMIN]
  },
  {
    path: '/wallets/:walletId/withdrawals',
    name: 'wallet_withdrawals',
    title: 'Withdrawals',
    section: serviceWalletSection,
    exact: true,
    component: WithdrawalsList,
    roles: [PAYMENT_ADMIN]
  },
  {
    path: '/wallets/:walletId/withdrawals/new',
    name: 'wallet_withdrawal',
    title: 'New Withdrawal',
    section: serviceWalletSection,
    exact: true,
    parent: 'wallets',
    component: NewWithdrawal,
    roles: [PAYMENT_ADMIN]
  },
  {
    path: '/wallets/:walletId/payments',
    name: 'wallet_payments',
    title: i18n.t('can.common.payments'),
    section: serviceWalletSection,
    parent: 'wallets',
    exact: true,
    component: PaymentsAdminService,
    roles: [PAYMENT_ADMIN]
  }
];

const StatisticsRoutes = [
  {
    path: '/statistics/can-topology',
    name: 'topology_statistics',
    title: i18n.t('can.sidebar.canTopologyStatistics'),
    section: statisticsSection,
    exact: true,
    menu: true,
    component: CanTopologyStatistics,
    roles: [CAN_STATISTICS, WM_ADMIN, CAN_SPONSOR, GENERATE_SUBSET_OF_DATASET]
  },
  {
    path: '/statistics/node-activity',
    name: 'node_activity_statistics',
    title: i18n.t('can.sidebar.nodeActivityStatistics'),
    backLink: i18n.t(
      'can.statistics.nodeActivityDetails.routes.backToNodeActivity'
    ),
    section: statisticsSection,
    exact: true,
    menu: true,
    component: NodeActivityList,
    roles: [CAN_STATISTICS, WM_ADMIN, CAN_SPONSOR, GENERATE_SUBSET_OF_DATASET]
  },
  {
    path: '/statistics/node-activity/:nodeId',
    parent: 'node_activity_statistics',
    name: 'node_activity_statistics_details',
    title: i18n.t('can.statistics.nodeActivityDetails.title'),
    section: statisticsSection,
    exact: true,
    component: NodeActivityDetails,
    roles: [CAN_STATISTICS, WM_ADMIN, CAN_SPONSOR, GENERATE_SUBSET_OF_DATASET]
  }
];

const ParticipantsManagementRoutes = [
  {
    path: '/dashboard',
    name: 'participation_dashboard',
    title: i18n.t('can.sidebar.participationDashboard'),
    section: participantsManagementSection,
    exact: true,
    menu: true,
    component: ParticipationDashboard,
    roles: [WM_ADMIN, GENERATE_SUBSET_OF_DATASET]
  },
  {
    path: '/participants',
    name: 'participants',
    title: i18n.t('mwAdmin.sidebar.participants'),
    backLink: i18n.t('routes.backToParticipantList'),
    section: participantsManagementSection,
    exact: true,
    icon: '',
    menu: true,
    component: ParticipantsList,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/participants/new',
    name: 'participants_new',
    title: i18n.t('mwAdmin.invitations.newParticipant'),
    parent: 'participants',
    section: participantsManagementSection,
    exact: true,
    icon: '',
    component: NewParticipant,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS
    ]
  },
  {
    path: '/participants/:id/view',
    name: 'participants_view',
    title: i18n.t('mwAdmin.invitations.participantDetails'),
    parent: 'participants',
    section: participantsManagementSection,
    exact: true,
    icon: '',
    component: ParticipantDetails,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS
    ]
  }
];

const AliasRoutes = [
  {
    path: '/',
    exact: true,
    redirectURL: '/dashboard'
  },
  {
    path: '/not-found',
    name: 'not_found',
    exact: true,
    title: 'Page not found',
    component: NotFoundPage
  },
  {
    path: '/no-admin-rights',
    exact: true,
    title: 'No Admin Rights',
    component: NoAdminRights
  }
];

export default [
  ...RoleRoutes,
  ...NetworkRoutes,
  ...GroupRoutes,
  ...AliasRoutes,
  ...DepositsRoutes,
  ...PaymentsRoutes,
  ...ServiceWallets,
  ...StatisticsRoutes,
  ...ParticipantsManagementRoutes
];

// routes for bi
export const menuSections = [
  {
    title: i18n.t('mwAdmin.sidebar.participantsManagement'),
    icon: <ParticipantsManagement />,
    routes: [...ParticipantsManagementRoutes, ...NetworkRoutes].filter(
      getMenuItems
    )
  },
  {
    title: i18n.t('mwAdmin.sidebar.careProtocol'),
    icon: <ConfigurationIcon />,
    routes: [...RoleRoutes].filter(getMenuItems)
  },
  {
    title: i18n.t('can.sidebar.canStatistics'),
    icon: <StatisticsIcon />,
    routes: [...StatisticsRoutes].filter(getMenuItems)
  },
  {
    routes: [...ServiceWallets].filter(getMenuItems)
  },
  {
    title: i18n.t('can.sidebar.solveManagement'),
    routes: [...DepositsRoutes].filter(getMenuItems)
  },
  {
    title: i18n.t('can.sidebar.payments'),
    routes: [...PaymentsRoutes].filter(getMenuItems)
  }
];

// TODO: del after implementing new roles
// routes for can
export const menuLinksConfig = {
  [WM_ADMIN]: [
    {
      title: i18n.t('mwAdmin.sidebar.participantsManagement'),
      routes: [...ParticipantsManagementRoutes, ...NetworkRoutes].filter(
        getMenuItems
      )
    },
    {
      title: i18n.t('mwAdmin.sidebar.networkConfiguration'),
      routes: [...RoleRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('can.sidebar.canStatistics'),
      routes: [...StatisticsRoutes].filter(getMenuItems)
    }
  ],
  [PAYMENT_ADMIN]: [
    {
      routes: [...ServiceWallets].filter(getMenuItems)
    }
  ],
  [CAN_SPONSOR]: [
    {
      title: i18n.t('can.sidebar.solveManagement'),
      routes: [...DepositsRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('can.sidebar.payments'),
      routes: [...PaymentsRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('can.sidebar.canStatistics'),
      routes: [...StatisticsRoutes].filter(getMenuItems)
    }
  ],
  [CAN_STATISTICS]: [
    {
      routes: [...StatisticsRoutes].filter(getMenuItems)
    }
  ]
};
