/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core';

// Components
import { PhoneNumberField } from '@CommonScene';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';

// Utils
import { compose } from '@Utils';
import { getPublicPath } from '@Utils/constans/paths';

// Styles
import styles from './styles';

export class ResetPasswordPhoneForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    AuthStore: PropTypes.shape({
      forms: PropTypes.object,
      onFieldChange: PropTypes.func,
      sendPhoneForResetPassword: PropTypes.func,
      clearInputFields: PropTypes.func,
      clearErrorMessage: PropTypes.func
    }).isRequired,
    t: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.AuthStore.clearInputFields('resetPasswordPhone', ['phone']);
  }

  handleSubmitForm = e => {
    e.preventDefault();

    this.props.AuthStore.sendPhoneForResetPassword();
  };

  onFieldChange = e => {
    const {
      AuthStore: { onFieldChange, clearErrorMessage }
    } = this.props;

    clearErrorMessage();
    onFieldChange(e.target.name, e.target.value, 'resetPasswordPhone');
  };

  backHandler = () => this.props.history.push(getPublicPath.login());

  render() {
    const {
      classes,
      AuthStore: { forms, errors },
      t
    } = this.props;

    return (
      <form onSubmit={this.handleSubmitForm}>
        <HeaderTitle>{t('auth.resetPassword.stage1.heading')}</HeaderTitle>

        <PhoneNumberField
          id="phone-input"
          className={classes.fieldWidth}
          name="phone"
          label={t('auth.resetPassword.stage1.phoneLabel')}
          value={forms.resetPasswordPhone.fields.phone.value}
          error={forms.resetPasswordPhone.fields.phone.error}
          onChange={this.onFieldChange}
        />

        <ErrorSection errors={errors} />

        <ResetPasswordButtons
          backBtnText={t('common.back')}
          nextBtnText={t('common.next')}
          backBtnOnclickHandler={this.backHandler}
          primaryBtnDisabled={
            forms.resetPasswordPhone.fields.phone.value.length < 11
          }
        />
      </form>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withTranslation(),
  inject('AuthStore'),
  observer
)(ResetPasswordPhoneForm);
