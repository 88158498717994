/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, computed } from 'mobx';
import i18n from 'i18next';

// Providers
import { UserProvider } from '@Providers';

// Utils
import { getParsedCookies } from '@Utils/formatting';

export class CommonStore {
  CURRENT_USER_INFO = 'currentUserInfo';

  DOMAIN = process.env.DOMAIN;

  AUTHORIZATION_API = `${this.DOMAIN}/authorization-service`;

  AUTHENTICATION_API = `${this.DOMAIN}/authentication-service`;

  BILLING_API = `${this.DOMAIN}/billing-service`;

  NPS_API = `${this.DOMAIN}/nps`;

  BRIDGE_API = `${this.DOMAIN}/bridge-service`;

  CARE_PROTOCOL_API = `${this.DOMAIN}/care-protocol-service`;

  REPORTING_API = `${this.DOMAIN}/reporting-endpoint-service`;

  // TODO: temporary value! Need to separate logic for different clients
  isACN = this.DOMAIN && this.DOMAIN.includes('acn');

  isBI = this.DOMAIN && this.DOMAIN.includes('bi');

  @observable userRole = null;

  @observable pending = false;

  @observable currentPath = '';

  @observable currentTitle = '';

  @observable currentUserInfo =
    JSON.parse(window.localStorage.getItem(this.CURRENT_USER_INFO)) || {};

  @observable isPageNotFound = false;

  @observable modalName = null;

  @observable modalProps = {};

  @observable dynamicMenuItems = {};

  @observable dynamicTitleItems = {};

  @observable headerId = '';

  @observable isBtnDisabled = false;

  @observable backPath = null;

  @computed get fullUserName() {
    if (!this.currentUserInfo) {
      return null;
    }

    return `${this.currentUserInfo.first_name || ''} ${this.currentUserInfo
      .last_name || ''}`;
  }

  @action('CommonStore => setBackPath') setBackPath = backPath => {
    if (!backPath.url) {
      throw new Error('url in backPath is required');
    }

    this.backPath = backPath;
  };

  @action('CommonStore => clearBackPath') clearBackPath = () => {
    this.backPath = null;
  };

  @action('CommonStore => setBtnDisabled') setBtnDisabled = () => {
    this.isBtnDisabled = true;
  };

  @action('CommonStore => setBtnEnabled') setBtnEnabled = () => {
    this.isBtnDisabled = false;
  };

  @action('CommonStore => setDynamicMenuItems') setDynamicMenuItems = ({
    id,
    name
  }) => {
    this.headerId = id;
    this.dynamicMenuItems = {
      ...this.dynamicMenuItems,
      [id]: `${name} ${i18n.t('can.common.wallet')}`
    };
    this.dynamicTitleItems = {
      ...this.dynamicMenuItems,
      [id]: name
    };
  };

  @computed get dynamicMenuItem() {
    return this.dynamicMenuItems[this.headerId]
      ? this.dynamicMenuItems[this.headerId]
      : `${i18n.t('can.common.wallet')}`;
  }

  @action('CommonStore => get user role') getUserRole = () => {
    this.userRole = getParsedCookies().role;
  };

  @action('CommonStore => set modal name & props') setModalOptions = ({
    modalName,
    modalProps
  }) => {
    this.modalName = modalName;
    this.modalProps = modalProps;
  };

  @action('CommonStore => update modal props')
  updateModalProps = modalProps => {
    this.modalProps = {
      ...this.modalProps,
      ...modalProps
    };
  };

  @action('CommonStore => delete modal name & props') delModalOptions = () => {
    this.modalName = null;
    this.modalProps = {};
  };

  @action setCurrentPath = pathName => {
    this.currentPath = pathName;
  };

  @action setCurrentTitle = titleName => {
    this.currentTitle = titleName;
  };

  @action _setUserInfo = userInfo => {
    this.currentUserInfo = userInfo;
    window.localStorage.setItem(
      this.CURRENT_USER_INFO,
      JSON.stringify(userInfo)
    );
  };

  @action _removeUserInfo() {
    this.currentUserInfo = {};
    window.localStorage.removeItem(this.CURRENT_USER_INFO);
  }

  @action setPending = () => {
    this.pending = true;
  };

  @action clearPending = () => {
    this.pending = false;
  };

  @action handleNotFoundError = () => {
    this.isPageNotFound = true;
  };

  @action skipNotFoundError = () => {
    this.isPageNotFound = false;
  };

  @action getCurrentUser = () => {
    UserProvider.getCurrentUser().then(this._setUserInfo);
  };

  @action reset = () => {
    this._removeUserInfo();
    this.skipNotFoundError();
    this.clearPending();
    this.setBtnEnabled();
  };
}

export default new CommonStore();
