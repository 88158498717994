/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import i18n from 'i18next';
import shortid from 'shortid';

// Stores
import { UserRolesStore, AdminRolesStore } from '@Stores/RolesStore';

// Utils
import { translation } from '@Utils/translation';
import { handleRowClick } from '@Utils';

export const renderTermsVersion = options =>
  options && options.termsAndConditions
    ? options.termsAndConditions.version
    : '-';

export const renderJoiningType = options => {
  return options && options.is_public_access
    ? i18n.t('mwAdmin.roles.publicAccess')
    : i18n.t('mwAdmin.roles.invitationOnly');
};

export const renderCardsNames = cards => {
  return cards.map((card, index) => (
    <React.Fragment key={shortid.generate()}>
      {index === cards.length - 1 ? card.name : `${card.name}, `}
    </React.Fragment>
  ));
};

export const renderRoleName = name => translation(name, 'rolesNames');

export class TableConfig {
  constructor(props) {
    if (!props.setOrder) {
      throw new Error('setOrder is required in TableConfig for roles');
    }

    this.headings = [
      {
        id: 'name',
        title: i18n.t('common.role'),
        sortable: true,
        cb: props.setOrder
      },
      i18n.t('mwAdmin.roles.joiningType'),
      props.rolesAssigned,
      i18n.t('mwAdmin.roles.termsAndConditions')
    ];

    Object.keys(props).forEach(key => {
      this[key] = props[key];
    });
  }

  defaultOrder = 'asc';

  defaultOrderBy = 'name';

  rowIdKey = 'id';

  rowCells = [
    { key: 'name', cb: renderRoleName },
    { key: 'joining_options', cb: renderJoiningType },
    { key: 'resources', cb: renderCardsNames },
    { key: 'joining_options', cb: renderTermsVersion }
  ];
}

export const adminRolesTableConfig = new TableConfig({
  setOrder: AdminRolesStore.setOrder,
  rolesAssigned: i18n.t('mwAdmin.roles.assignedModules')
});

export const userRolesTableConfig = new TableConfig({
  setOrder: UserRolesStore.setOrder,
  handleRowClick: handleRowClick(['/user-roles/', '/edit']),
  rolesAssigned: i18n.t('mwAdmin.roles.assignedCards')
});
