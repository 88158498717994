/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Button } from '@material-ui/core';

// Utils
import classnames from 'classnames';

// Styles
import useStyles from './styles';

export const SimpleButton = ({
  onClick,
  children,
  disabled,
  className,
  primary,
  secondary,
  checkbox,
  ...otherProps
}) => {
  const classes = useStyles(otherProps);

  return (
    <Button
      className={className}
      onClick={onClick}
      classes={{
        disabled: classes.disabled,
        root: classnames(
          classes.root,
          primary ? classes.primaryRoot : null,
          secondary ? classes.secondaryRoot : null,
          checkbox ? classes.checkbox : null
        )
      }}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

SimpleButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  checkbox: PropTypes.bool
};

SimpleButton.defaultProps = {
  disabled: false
};

export const PrimaryButton = props => <SimpleButton primary {...props} />;

export const CheckboxButton = props => <SimpleButton checkbox {...props} />;

export const SecondaryButton = props => <SimpleButton secondary {...props} />;
