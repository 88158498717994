/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material UI
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import { MainContentWrapper } from '@CommonScene';
import Editor from '@RolesScene/scenes/common/TermsAndConditions/Editor';

// Utils
import { useStore } from '@Utils/hooks';
import { compose } from '@Utils';
import { getRolesPath } from '@Utils/constans/paths';

// Styles
const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    '& button': {
      marginLeft: '10px'
    }
  }
}));

export const NewTermsAndConditions = ({ match, history }) => {
  const classes = useStyles();
  const {
    TermsAndConditionsStore: {
      createTermsAndConditionsDraft,
      clearTermsAndConditionsText
    }
  } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    clearTermsAndConditionsText();
  }, []);

  const {
    params: { roleId }
  } = match;

  const HEADER_OPTIONS = {
    title: 'Terms and Conditions'
  };

  return (
    <MainContentWrapper headerOptions={HEADER_OPTIONS}>
      <Editor id="editor" />
      <Grid container justify="flex-end" className={classes.buttonsWrapper}>
        <Grid item>
          <Button
            id="cancel-btn"
            variant="contained"
            component={Link}
            to={getRolesPath.userRoleView({ roleId })}
          >
            {t('common.cancel')}
          </Button>
          <Button
            id="create-btn"
            variant="contained"
            color="secondary"
            onClick={() =>
              createTermsAndConditionsDraft(roleId).then(() =>
                history.push(getRolesPath.userRoleView({ roleId }))
              )
            }
          >
            {t('common.create')}
          </Button>
        </Grid>
      </Grid>
    </MainContentWrapper>
  );
};

NewTermsAndConditions.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(NewTermsAndConditions);
