/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// stores
import MembersPaymentsStore from '@Stores/MembersPaymentsStore';

// Components
import HeadingFilter from '@CommonScene/DataTable/HeaderRow/components/HeadingFilter';

// Utils
import {
  addCommasInNumber,
  formatDate,
  hideFieldsInfo
} from '@Utils/formatting';
import { paymentStatuses } from '@Utils/constans';

export const sortData = orderBy => () =>
  MembersPaymentsStore.sortData({ orderBy });

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'created_date',
  headings: [
    {
      id: 'created_date',
      title: i18n.t('can.members.transactions.table.date'),
      sortable: true,
      cb: sortData('created_date')
    },
    <HeadingFilter
      items={paymentStatuses}
      selectedItems={MembersPaymentsStore.selectedStatuses}
      label={i18n.t('can.members.transactions.table.status')}
      applyHandler={MembersPaymentsStore.filterByStatus}
      onChangeHandler={MembersPaymentsStore.handleStatusSelect}
      key={shortid.generate()}
    />,
    {
      id: 'recipient',
      title: i18n.t('can.members.transactions.table.recipient'),
      sortable: true,
      cb: sortData('recipient')
    },
    i18n.t('can.members.transactions.table.paymentCategory'),
    {
      id: 'fiat_amount',
      title: i18n.t('can.members.transactions.table.usdAmount'),
      sortable: true,
      cb: sortData('fiat_amount')
    },
    {
      id: 'exchange_rate',
      title: i18n.t('can.members.transactions.table.solveValue'),
      sortable: true,
      cb: sortData('exchange_rate')
    },
    {
      id: 'solve_amount',
      title: i18n.t('can.members.transactions.table.solveAmount'),
      sortable: true,
      cb: sortData('solve_amount')
    }
  ],
  rowCells: [
    { key: 'created_date', cb: formatDate },
    'status',
    { key: 'recipient', cb: hideFieldsInfo },
    'payment_category',
    { key: 'fiat_amount', cb: addCommasInNumber },
    'exchange_rate',
    { key: 'solve_amount', cb: addCommasInNumber }
  ]
};
