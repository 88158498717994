/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import i18n from 'i18next';

export const accessStatusTexts = {
  REMOVED: 'You are not joined to Arizona Care Network.',
  BLOCKED: 'Your access to Arizona Care network is blocked.'
};

export const DELAY_REQUEST = 300;

export const paymentStatuses = [
  {
    id: 'Pending',
    name: i18n.t('can.services.transactions.paymentStatuses.pending')
  },
  {
    id: 'Paid',
    name: i18n.t('can.services.transactions.paymentStatuses.paid')
  },
  {
    id: 'Unpaid',
    name: i18n.t('can.services.transactions.paymentStatuses.unpaid')
  }
];

export const validationKeys = {
  'The login field is required.': 'auth.validation.loginRequired',
  'The password field is required.': 'auth.validation.passwordRequired',
  'The login must be at least 6 characters.': 'auth.validation.loginLength',
  'The password must be at least 8 characters.':
    'auth.validation.passwordLength',
  'The phone field is required.': 'auth.validation.phoneRequired',
  Next: 'auth.resetPassword.common.next',
  Reset: 'auth.resetPassword.common.reset',
  'The code must be at least 6 characters.': 'auth.validation.codeLength',
  'The Password and Confirm Password fields must match.':
    'auth.validation.matchPassword',
  'The password format is invalid.': 'auth.validation.wrongPasswordFormat',
  'The password field must be between 8 and 40.':
    'auth.validation.passwordLength',
  'The name field is required.': 'mwAdmin.roles.nameRequired'
};

export const editableRoleTypes = ['WALLET'];

export const TERMS_REQUIREMENT = {
  DISABLED: 'termsDisabled',
  ENABLED: 'termsEnabled'
};

export const participantStatuses = [
  { name: 'PREASSIGNED' },
  // { name: 'ARCHIVED' },
  { name: 'ACTIVE' },
  // { name: 'BLOCKED' },
  { name: 'INVITATION_PENDING' },
  { name: 'INVITATION_EXPIRED' }
];
