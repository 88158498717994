/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Material UI
import { Typography, withStyles } from '@material-ui/core';

// Components
import ModalConfirmation from '@CommonScene/modals/ModalConfirmationSnippet';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export const ModalErrorRemovingRole = ({
  clearErrorMessage,
  classes,
  haveErrors,
  t
}) => (
  <ModalConfirmation
    id="modal-wrapper"
    isOpen={haveErrors}
    cancelBtn={t('common.goBack')}
    handleCancelAction={clearErrorMessage}
  >
    <Typography className={classes.bottomSpace} variant="h6">
      {t('modals.deleteRole')}
    </Typography>
    <Typography
      className={classNames(classes.noMargin, classes.greyText)}
      variant="body1"
    >
      {t('modals.deleteAssignedRole')}
    </Typography>
  </ModalConfirmation>
);

ModalErrorRemovingRole.defaultProps = {
  haveErrors: false
};

ModalErrorRemovingRole.propTypes = {
  clearErrorMessage: PropTypes.func.isRequired,
  classes: PropTypes.object,
  haveErrors: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withTranslation()
)(ModalErrorRemovingRole);
