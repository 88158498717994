/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { Button, Grid, makeStyles } from '@material-ui/core';

// Component
import FilterContentComponent from '@CommonScene/FilterContentComponent';

const useStyles = makeStyles(theme => ({
  filterContentWrapper: {
    position: 'absolute',
    zIndex: 1,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4),
    background: '#FFFFFF',
    top: 55,
    right: 0,
    width: '410px',
    height: '340px',
    boxShadow: '0px 1px 14px rgba(203, 200, 218, 0.7)',
    [theme.breakpoints.up('xl')]: {
      width: '600px',
      height: '415px'
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      right: 17,
      top: -12,
      width: 0,
      height: 0,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderBottom: '12px solid #FFFFFF'
    }
  },
  filterComponent: {
    flex: '1 0 0',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  buttonsWrap: {
    width: '100%'
  },
  buttonRoot: {
    color: '#A095AE',
    marginRight: theme.spacing(3)
  },
  actionsBlock: {
    paddingTop: `${theme.spacing(2)}px`,
    '& button': {
      padding: 0,
      minWidth: 'unset'
    },
    '& button:hover': {
      background: 'unset'
    },
    '& > button:last-child': {
      color: '#9B51E0'
    },
    '& > button[disabled]:last-child': {
      color: 'rgba(0, 0, 0, 0.26)'
    }
  }
}));

const FilterModal = ({
  open,
  filters,
  toggleStatusChecked,
  filterCounter,
  maxFilterCounter,
  handleReset,
  handleClear,
  handleApply,
  disabledApplyBtn
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Grid container className={classes.filterContentWrapper} direction="column">
      <Grid item className={classes.filterComponent}>
        <FilterContentComponent
          filters={filters}
          toggleStatusChecked={toggleStatusChecked}
        />
      </Grid>
      <Grid item classes={{ root: classes.buttonsWrap }}>
        <Grid
          container
          justify="space-between"
          className={classes.actionsBlock}
        >
          <div>
            {filterCounter !== maxFilterCounter ? (
              <Button
                classes={{ root: classes.buttonRoot }}
                onClick={handleReset}
              >
                {t('common.reset')}
              </Button>
            ) : null}
            {filterCounter ? (
              <Button
                classes={{ root: classes.buttonRoot }}
                onClick={handleClear}
              >
                {t('common.clear')}
              </Button>
            ) : null}
          </div>
          <Button disabled={disabledApplyBtn} onClick={handleApply}>
            {t('common.apply')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  toggleStatusChecked: PropTypes.func.isRequired,
  filterCounter: PropTypes.number.isRequired,
  disabledApplyBtn: PropTypes.bool.isRequired,
  maxFilterCounter: PropTypes.number.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired
};

export default FilterModal;
