/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action } from 'mobx';
import { debounce } from 'lodash';

// Store
import CommonStore from '@Stores/CommonStore';

// Providers
import { DepositsProvider } from '@Providers/index';

// Constants
import { DELAY_REQUEST } from '@Utils/constans';

const withBaseDepositsMethods = Base => {
  return class extends Base {
    @action('withBaseDepositsMethods => setOrder') setOrder = orderBy => {
      if (this.orderBy !== orderBy) {
        // set default sorting if new column is clicked
        this.order = 'desc';
      } else if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
      this.orderBy = orderBy;

      return this.getDepositsTransactions();
    };

    @action('withBaseDepositsMethods => setQuery') setQuery = query => {
      this.query = query;
      this._searchWithDebounce();
    };

    @action('withBaseDepositsMethods => clearQuery') clearQuery = () => {
      this.query = '';
    };

    @action('withBaseDepositsMethods => _searchQuery') _searchQuery = () => {
      this._resetTransactions();
      this.getDepositsTransactions();
    };

    _searchWithDebounce = debounce(this._searchQuery, DELAY_REQUEST);

    @action('withBaseDepositsMethods => getBalance') getBalance = () => {
      CommonStore.setPending();
      return DepositsProvider.getBalance()
        .then(this.setBalance)
        .catch(this._handleErrors)
        .finally(CommonStore.clearPending);
    };

    @action('withBaseDepositsMethods => setBalance') setBalance = ({
      balance
    }) => {
      this.balance = balance;
    };
  };
};

export default withBaseDepositsMethods;
