/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, computed, set } from 'mobx';
import { isObject, without, isEmpty, debounce } from 'lodash';

// Utils
import { filterSuggestions } from '@Utils/sorting';

// Providers
import { UserProvider, RoleProvider, InvitationProvider } from '@Providers';

// stores
import BaseListStore from '@Stores/BaseListStore';
import CommonStore from '@Stores/CommonStore';
import InvitationStore from '@Stores/InvitationStore';

// config
import { NetworkParticipantsForms as forms } from '@Assets/config/configForNetworkForms';
import { userStatuses } from '@Assets/config/configForStatuses';
import { DELAY_REQUEST } from '@Utils/constans';

class NetworkStore extends BaseListStore {
  constructor() {
    super();

    this.orderBy = 'firstName';
  }

  userId = null;

  roleId = null;

  roleTemplateId = null;

  currentGroupId = null;

  userName = null;

  _currentUserStatus = null;

  users = observable.array([], { deep: false });

  groupUsers = observable.array([], { deep: false });

  filter = observable.array([], { deep: false });

  customFields = observable.array([], { deep: false });

  roleTemplates = observable.array([], { deep: false });

  roleOptions = observable.array([], { deep: false });

  fields = InvitationStore.fields;

  @observable userStatus = userStatuses;

  @observable allStatusesChecked = false;

  @observable forms = forms;

  @observable openAddRoleModal = false;

  @observable currentUser = {};

  @observable currentUserRoles = null;

  @observable currentRoleId = null;

  @observable userCustomField = {};

  @observable totalRows = 25;

  @observable lastPage = false;

  @observable groupUsersPage = 0;

  @observable deletedRoles = null;

  @observable rolesToDelete = null;

  @observable lastUsersPage = false;

  @computed get isAddRoleFieldsValid() {
    return (
      !isEmpty(this.userCustomField) &&
      Boolean(this.forms.networkForm.fields.role.value)
    );
  }

  // Not usable for now. Just for Sonar validation
  set isAddRoleFieldsValid(value) {
    this.forms.networkForm.meta.isValid = value;
  }

  @computed get filteredUsers() {
    const f = this.filter;
    return f.length
      ? this.users.filter(user => f.includes(user.id))
      : this.users;
  }

  // Not usable for now. Just for Sonar validation
  set filteredUsers(value) {
    this.users = value;
  }

  @action('NetworkStore => _setCurrentUser') _setCurrentUser = (
    user,
    formToSaveData
  ) => {
    this.currentUser = user;
    this._setUserFormData(user, formToSaveData);
  };

  @action('NetworkStore => _setUserFormData') _setUserFormData = (
    user,
    formToSaveData = 'networkEditForm'
  ) => {
    const fields = this.forms[formToSaveData].fields;
    fields.name.value = user.first_name || '';
    fields.surname.value = user.last_name || '';
    if (formToSaveData === 'inviteEditForm') {
      fields.phone.value = user.phone_number || '';
    }
  };

  _checkPhoneInQuery = () => {
    const searchStr = this.searchQuery.trim();
    const regPhone = /^\+?(\d)(\s|-)?(\(\d{3}\)|(\d{3}))(\s|-)?\d{0,3}(\s|-)?\d{0,4}$/;
    const isPhone = regPhone.test(searchStr);

    return isPhone ? searchStr.replace(/\s|-|\(|\)/g, '') : searchStr;
  };

  @action('NetworkStore => setActualUserStatus') setActualUserStatus = () => {
    if (this._currentUserStatus) {
      this.userStatus = this._currentUserStatus;
    }
  };

  @action('NetworkStore => getUsersAccounts') getUsersAccounts = (
    orderBy = this.orderBy
  ) => {
    let queryS = {
      page: this.page,
      size: this.rowsPerPage,
      userStatus: this.getUserStatuses,
      sort: `${orderBy},${this.order}`
    };

    if (this.searchQuery) {
      queryS = {
        ...queryS,
        searchStr: this._checkPhoneInQuery(this.searchQuery)
      };
    }

    CommonStore.setPending();
    return UserProvider.getUsers(queryS)
      .then(this._setUsersAccounts)
      .finally(CommonStore.clearPending);
  };

  @action('NetworkStore => _setUsersAccounts') _setUsersAccounts = users => {
    this._currentUserStatus = { ...this.userStatus };
    this.setUsersList(users);
  };

  @action('NetworkStore => getGroupUsersAccounts') getGroupUsersAccounts = (
    groupId = this.currentGroupId,
    orderBy = this.orderBy
  ) => {
    const queryS = {
      groupId,
      page: this.page,
      size: this.rowsPerPage,
      userStatus: this.getUserStatuses,
      sort: `${orderBy},${this.order}`
    };

    CommonStore.setPending();
    return UserProvider.getUsers(queryS)
      .then(this._setGroupUsersAccounts)
      .finally(CommonStore.clearPending);
  };

  @action('NetworkStore => _setGroupUsersAccounts')
  _setGroupUsersAccounts = response => {
    const filteredUsers = without(response.content, 'undefined');
    this.groupUsersPage = response.number;
    this.lastUsersPage = response.last;
    if (this.groupUsersPage === 0) {
      this.groupUsers.replace([]);
    }
    const newGroupUsers = [...this.groupUsers, ...filteredUsers];
    this.groupUsers.replace(newGroupUsers);
  };

  @action('NetworkStore => _resetUsersPage') _resetUsersPage = () => {
    this.groupUsersPage = 0;
    this.lastUsersPage = false;
    this.currentGroupId = null;
  };

  @action('NetworkStore => resetOrderBy') resetOrderBy = () => {
    this.orderBy = 'firstName';
  };

  @action('NetworkStore => reset') reset() {
    this._resetUsersPage();
    this.resetPage();
    this.clearQuery();
    this.resetOrder();
    this.resetOrderBy();
  }

  setOrder = ({ groupId, orderBy }) => {
    this._changeOrder(orderBy);

    if (groupId) {
      this.getGroupUsersAccounts(groupId);
    } else {
      this.getUsersAccounts();
    }
  };

  @action('NetworkStore => clearGroupUsers') clearGroupUsers = () => {
    this.groupUsers.replace([]);
  };

  getActiveRoles = () => {
    CommonStore.setPending();
    return RoleProvider.getActiveRoles()
      .then(this.setRoleOptions)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  getAllTemplates = () => {
    CommonStore.setPending();
    return RoleProvider.getRoles({ size: 3000 })
      .then(this.setTemplates)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  getUser = (userId = this.currentUser.id, formToSaveData = '') => {
    CommonStore.setPending();
    return UserProvider.getUser(userId)
      .then(user => {
        this._setCurrentUser(user, formToSaveData);
      })
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  getUserRoles = userId => {
    CommonStore.setPending();
    return UserProvider.getUserRoles(userId)
      .then(this._setUserRoles)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('NetworkStore => setDeletedRoles') setDeletedRoles = deletedRoles => {
    this.deletedRoles = deletedRoles || null;
  };

  deleteUser = () => {
    CommonStore.setPending();
    return UserProvider.deleteUser(
      this.userId,
      this.forms.deleteForm.fields.reason.value
    )
      .then(this._handleDeleteUser)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  _handleDeleteUser = user => {
    this._setCurrentUser(user);
    this.clearUsers();
    this.getUsersAccounts();
  };

  @action('NetworkStore => resetForms') resetForms = () => {
    this.forms = forms;
  };

  blockUser = (id = this.userId) => {
    CommonStore.setPending();
    return UserProvider.blockUser(id)
      .then(this._setCurrentUser)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  unblockUser = (id = this.userId) => {
    CommonStore.setPending();
    return UserProvider.activateUser(id)
      .then(this._setCurrentUser)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('NetworkStore => setUserId') setUserId = id => {
    this.userId = id;
  };

  @action('NetworkStore => delUserId') delUserId = () => {
    this.userId = null;
  };

  updateUser = id => {
    CommonStore.setPending();
    return UserProvider.updateUser(id, this.currentUser)
      .then(this._setCurrentUser)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  addCustomFields = (groupId, roleTemplateId, userId) => {
    CommonStore.setPending();
    return InvitationProvider.inviteUser(groupId, roleTemplateId, userId)
      .then(this.getUser)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  deleteUserFromRole = (roleId, userId) => {
    CommonStore.setPending();
    return RoleProvider.deleteUserFromRole(roleId, userId)
      .then(() => {
        this.getUser(this.currentUser.id);
        this.getUserRoles(this.currentUser.id);
      })
      .catch(err => {
        if (err.status === 400) {
          CommonStore.setModalOptions({
            modalName: 'ModalError',
            modalProps: { text: err.response.body.error }
          });
        }
        this._handleServerError(err);
      })
      .finally(CommonStore.clearPending);
  };

  @action('NetworkStore => setPagesParams') setPagesParams = users => {
    this.totalRows = users.total_elements;
    this.lastPage = users.last;
  };

  @action('NetworkStore => setUsersList') setUsersList = users => {
    const filteredUsers = users.content.filter(n => isObject(n));
    this.setPagesParams(users);

    if (this.page === 0) {
      this.clearUsers();
    }

    if (this.users.length === this.totalRows) {
      return false;
    }

    const addNewUsers = [...this.users, ...filteredUsers];
    return this.users.replace(addNewUsers);
  };

  @computed get getUserStatuses() {
    return Object.keys(this.userStatus).filter(item => this.userStatus[item]);
  }

  // Not usable for now. Just for Sonar validation
  set getUserStatuses(value) {
    this.userStatus = value;
  }

  @action('NetworkStore => _setGroups') _setGroups = response => {
    const newCustomFields = filterSuggestions(response.content);
    this.customFields.replace(newCustomFields);
  };

  @action('NetworkStore => setTemplates') setTemplates = response => {
    this.roleTemplates.replace(response.content);
  };

  @action('NetworkStore => setRoleTemplateId')
  setRoleTemplateId = roleTemplateId => {
    this.roleTemplateId = roleTemplateId;
  };

  @action('NetworkStore => setRoleOptions') setRoleOptions = response => {
    const GroupTemplateId = 2;
    const options = response.content.filter(r => r.group !== GroupTemplateId);
    this.roleOptions.replace(options);
  };

  @action('NetworkStore => _setUserRoles') _setUserRoles = user => {
    this.currentUserRoles = user.roles;
  };

  _search = () => {
    this.resetPage();
    if (this.searchQuery) {
      this._checkAllStatuses();
    } else {
      this.setDefaultStatuses();
    }
    this.getUsersAccounts();
  };

  _searchWithDebounce = debounce(this._search, DELAY_REQUEST);

  @action('NetworkStore => _checkAllStatuses') _checkAllStatuses = () => {
    this.allStatusesChecked = true;
    this.userStatus = {
      ACTIVE: true,
      INVITED: true,
      BLOCKED: true,
      REMOVED: true
    };
  };

  @action('NetworkStore => handleStatusSelect') handleStatusSelect = ({
    type,
    checked
  }) => {
    this.userStatus[type] = checked;
    this.allStatusesChecked = Object.values(this.userStatus).every(
      item => item
    );
  };

  @action('NetworkStore => handleStatusAllSelect') handleStatusAllSelect = ({
    checked
  }) => {
    Object.keys(this.userStatus).forEach(item => {
      this.userStatus[item] = checked;
    });
    this.allStatusesChecked = checked;
  };

  @action('NetworkStore => resetCurrentUser') resetCurrentUser = () => {
    this.currentUser = {};
    this.currentUserRoles = null;
  };

  @action('NetworkStore => clearQuery') clearQuery = () => {
    this.searchQuery = '';
    this.setDefaultStatuses();
  };

  @action('NetworkStore => setDefaultStatuses') setDefaultStatuses = () => {
    this.allStatusesChecked = false;
    this.userStatus = userStatuses;
  };

  _updateUsers = () => {
    this.getUsersAccounts();
    this.resetPage();
    this.delUserId();
  };

  setBlockUser = () => {
    this.blockUser().then(this._updateUsers);
  };

  setUnblockUser = () => {
    this.unblockUser().then(this._updateUsers);
  };

  @action('NetworkStore => handleUserChangeCustomField')
  handleUserChangeCustomField = newCustomField => {
    this.userCustomField = newCustomField;
  };

  handleChangePage = newPage => {
    this.setPage(newPage);
    if (this.currentGroupId) {
      this.getGroupUsersAccounts();
    } else {
      this.getUsersAccounts();
    }
  };

  @action('NetworkStore => handleChangeUserName')
  handleChangeUserName = name => {
    set(this.currentUser, { first_name: name });
  };

  @action('NetworkStore => handleChangeUserSurname')
  handleChangeUserSurname = surname => {
    set(this.currentUser, { last_name: surname });
  };

  @action('NetworkStore => setCurrentGroup') setCurrentGroup = groupId => {
    this.currentGroupId = groupId;
  };

  @action('NetworkStore => clearUsers') clearUsers = () => {
    this.users.replace([]);
  };

  // TODO: deleted after rewriting roles
  setAdminGroup = roleId => {
    InvitationStore.setAdminGroup(roleId);
  };
}

export default new NetworkStore();
