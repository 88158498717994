/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, computed } from 'mobx';

const TIME_TO_CACHE = 300000; // 5min

class FilterStore {
  @observable filters = [];

  @observable filterCounter = 0;

  @observable selectedFilters = {};

  @observable timestampCachedData = 0;

  @computed get resendRequestForFilters() {
    return Date.now() > this.timestampCachedData;
  }

  // Filters example: [ { name: 'roles', items: [...roles] } ]
  @action('FilterStore => setFilters') setFilters = filters => {
    this.timestampCachedData = Date.now() + TIME_TO_CACHE;
    this.filters.replace(filters);
    this._setSelectedFilters();
    this._updateFilterCounter();
  };

  @action('FilterStore => clearFilters') clearFilters = () => {
    this.filters.replace([]);
  };

  @action('FilterStore => toggleStatusChecked') toggleStatusChecked = (
    filterItemKey,
    filterIndex
  ) => {
    this.filters[filterIndex].items.forEach((filterItem, index) => {
      if (filterItemKey === filterItem.name) {
        this.filters[filterIndex].items[index].checked = !filterItem.checked;
      }
    });

    this._setSelectedFilters();
    this._updateFilterCounter();
  };

  @action('FilterStore => _toggleAllStatuses') _toggleAllStatuses = checked => {
    this.filters.forEach((filter, filterIndex) => {
      filter.items.forEach((filterItem, index) => {
        this.filters[filterIndex].items[index].checked = checked;
      });
    });

    this._setSelectedFilters();
    this._updateFilterCounter();
  };

  deselectAllStatuses = () => {
    this._toggleAllStatuses(false);
  };

  selectAllStatuses = () => {
    this._toggleAllStatuses(true);
  };

  @action('FilterStore => set selected filters')
  _setSelectedFilters = () => {
    this.selectedFilters = this.filters.reduce((acc, currentFilter) => {
      const checkedItems = currentFilter.items.filter(
        checkedItem => checkedItem.checked
      );

      if (currentFilter.items.length === checkedItems.length) {
        acc[currentFilter.name] = [];
        return acc;
      }

      acc[currentFilter.name] = checkedItems.map(item => item.name);
      return acc;
    }, {});
  };

  @action('FilterStore => update filter counter')
  _updateFilterCounter = () => {
    this.filterCounter = this.filters.reduce((acc, currentFilter) => {
      const checkedItems = currentFilter.items.filter(
        checkedItem => checkedItem.checked
      );

      return acc + checkedItems.length;
    }, 0);
  };

  @action('FilterStore => reset') reset = () => {
    this.clearFilters();
    this.filterCounter = 0;
    this.timestampCachedData = 0;
    this.selectedFilters = {};
  };
}

export default new FilterStore();
