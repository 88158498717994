/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import {
  MainContentWrapper,
  PaymentsSummary,
  PaymentsFilter,
  DataTable
} from '@CommonScene';

// Configs
import { tableConfig } from '@Assets/config/tables/memberPayments';

// Utils
import { compose } from '@Utils';
import { getDateFilterKey } from '@Utils/formatting';

export class MemberPayments extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object
    }).isRequired,
    MembersPaymentsStore: PropTypes.shape({
      setOrder: PropTypes.func,
      getMemberSummary: PropTypes.func,
      memberSummary: PropTypes.object,
      getMemberTransactions: PropTypes.func,
      setPage: PropTypes.func,
      clearPaging: PropTypes.func,
      clearSearchCriteria: PropTypes.func,
      clearMemberTransactions: PropTypes.func,
      clearMemberSummary: PropTypes.func,
      updateSearchCriteria: PropTypes.func,
      reset: PropTypes.func,
      clearDateRange: PropTypes.func,
      updateMemberId: PropTypes.func,
      setDateRange: PropTypes.func,
      getAggregatedMemberTransactions: PropTypes.func,
      aggregatedMemberTransactions: PropTypes.array,
      memberTransactions: PropTypes.array,
      memberOptions: PropTypes.object,
      memberCareWalletId: PropTypes.string,
      membersId: PropTypes.array
    }).isRequired,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      history,
      MembersPaymentsStore: {
        setOrder,
        aggregatedMemberTransactions,
        memberCareWalletId,
        dateRange,
        updateSearchCriteria
      }
    } = this.props;

    if (!memberCareWalletId) {
      history.replace('/payments/members');
      return;
    }

    updateSearchCriteria(dateRange);

    if (!aggregatedMemberTransactions.length) {
      // TODO: temporary solution - because <select> with 1000 users isn't good
      this.getAggregatedMemberTransactions();
    }

    setOrder({
      orderBy: tableConfig.defaultOrderBy
    });

    this.getMembersTransactionsAndSummary();
  }

  componentWillUnmount() {
    this.props.MembersPaymentsStore.reset();
  }

  getAggregatedMemberTransactions = () => {
    const {
      MembersPaymentsStore: { getAggregatedMemberTransactions }
    } = this.props;

    // TODO: temporary solution - because <select> with 1000 users isn't good
    getAggregatedMemberTransactions({ pageSize: 1000, orderBy: 'owner' });
  };

  getMembersTransactionsAndSummary = () => {
    const {
      MembersPaymentsStore: {
        getMemberSummary,
        getMemberTransactions,
        memberCareWalletId
      }
    } = this.props;

    getMemberSummary({ memberCareWalletId });
    getMemberTransactions({ memberCareWalletId });
  };

  clearData = () => {
    const {
      MembersPaymentsStore: {
        clearPaging,
        clearMemberTransactions,
        clearMemberSummary
      }
    } = this.props;

    clearPaging();
    clearMemberTransactions();
    clearMemberSummary();
  };

  handleChangeSelect = event => {
    const {
      MembersPaymentsStore: { updateMemberId, clearSearchCriteria }
    } = this.props;

    updateMemberId(event.target.value);
    this.clearData();
    clearSearchCriteria();
    this.getMembersTransactionsAndSummary();
  };

  fetchTableData = () => {
    const {
      MembersPaymentsStore: {
        setPage,
        getMemberTransactions,
        memberCareWalletId
      }
    } = this.props;

    setPage();
    getMemberTransactions({ memberCareWalletId });
  };

  updateTableData = searchCriteria => {
    const {
      MembersPaymentsStore: {
        updateSearchCriteria,
        updateAggregatedSearchCriteria,
        clearDateRange,
        clearAggregatedMemberTransactions
      }
    } = this.props;

    clearDateRange();
    this.clearData();
    clearAggregatedMemberTransactions();
    updateSearchCriteria(searchCriteria);
    updateAggregatedSearchCriteria(searchCriteria);
    this.getMembersTransactionsAndSummary();
    this.getAggregatedMemberTransactions();
  };

  handleChangeDate = (date, key) => {
    const {
      MembersPaymentsStore: { setDateRange }
    } = this.props;

    setDateRange({ [getDateFilterKey(key)]: date });
    this.updateTableData({ [getDateFilterKey(key)]: date });
  };

  getHeaderOptions = () => ({
    title: this.props.t('can.members.transactions.title')
  });

  render() {
    const {
      MembersPaymentsStore: {
        memberSummary,
        membersId,
        memberCareWalletId,
        memberTransactions,
        memberOptions,
        dateRange
      },
      t
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <PaymentsFilter
          selectName={t('can.members.transactions.selectMemberTitle')}
          selectList={membersId}
          selectValue={memberCareWalletId}
          handleChangeSelect={this.handleChangeSelect}
          handleChangeDate={this.handleChangeDate}
          dateRange={dateRange}
        />
        <PaymentsSummary transactionsSummary={memberSummary} />
        <DataTable
          tableConfig={tableConfig}
          tableData={memberTransactions}
          fetchTableData={this.fetchTableData}
          hasMoreData={!memberOptions.last}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(),
  inject('MembersPaymentsStore'),
  observer
)(MemberPayments);
