/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import i18n from 'i18next';

// Material UI
import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';

// Components
import { Checkbox } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export const Items = ({
  classes,
  anchorEl,
  items,
  selectedItems,
  handleClose,
  applyFilter,
  onChangeHandler
}) => (
  <Menu anchorEl={anchorEl} open onClose={handleClose} id="menu">
    <div className={classes.listWrapper}>
      {items.length
        ? items.map(item => (
            <MenuItem key={shortid.generate()}>
              <Checkbox
                id="checkbox-item"
                className={classes.checkboxLabel}
                checked={selectedItems.indexOf(item.id) >= 0}
                onChange={() => onChangeHandler(item.id)}
                label={item.name}
              />
            </MenuItem>
          ))
        : null}
    </div>
    <div className={classes.menuItemFilter}>
      <Button onClick={handleClose} id="cancel-btn">
        {i18n.t('common.cancel')}
      </Button>
      <Button onClick={applyFilter} id="apply-btn">
        {i18n.t('common.apply')}
      </Button>
    </div>
  </Menu>
);

Items.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired
};

Items.efaultProps = {
  classes: {}
};

export default compose(
  withStyles(styles),
  observer
)(Items);
