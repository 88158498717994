/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: `${theme.spacing(3)}px`,
    padding: `${theme.spacing()}px ${theme.spacing(4)}px`
  },
  primaryRoot: {
    color: '#FFF',
    backgroundColor: '#9B51E0',
    '&:hover': {
      backgroundColor: '#9B51E0'
    }
  },
  secondaryRoot: {
    color: '#9B51E0',
    backgroundColor: '#E9E2F4',
    '&:hover': {
      backgroundColor: '#E9E2F4'
    }
  },
  checkbox: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    textTransform: 'none',
    lineHeight: `${theme.spacing(2)}px`,
    color: props => (props.checked ? '#FFFFFF' : '#2F0A6B'),
    border: props =>
      props.checked ? '1px solid #9B51E0' : '1px solid #E9E2F4',
    backgroundColor: props => (props.checked ? '#9B51E0' : '#FFFFFF'),
    '&:hover': {
      backgroundColor: props => (props.checked ? '#9B51E0' : '#FFFFFF')
    }
  },
  disabled: {
    color: '#A095AE',
    backgroundColor: '#F1F2F4'
  }
}));

export default useStyles;
