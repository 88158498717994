/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

export const ParticipantPortrait = ({ color }) => (
  <svg width="44" height="46" viewBox="0 0 44 46" fill="none">
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="0"
      y="17"
      width="44"
      height="29"
    >
      <path
        d="M0.166656 17.3334H43.5V44.5C43.5 45.0523 43.0523 45.5 42.5 45.5H1.16666C0.61437 45.5 0.166656 45.0523 0.166656 44.5V17.3334Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <ellipse cx="21.8333" cy="45.5" rx="21.6667" ry="21.6667" fill={color} />
    </g>
    <ellipse cx="22.9167" cy="9.75" rx="9.75" ry="9.75" fill={color} />
  </svg>
);

ParticipantPortrait.defaultProps = {
  color: '#9B51E0'
};

ParticipantPortrait.propTypes = {
  color: PropTypes.string
};
