/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import ButtonsSection from '@ParticipantsManagementScene/scenes/ParticipantDetails/components/ButtonsSection';
import PersonalInfo from '@ParticipantsManagementScene/scenes/ParticipantDetails/components/PersonalInfo';
import GeneralInfo from '@ParticipantsManagementScene/scenes/ParticipantDetails/components/GeneralInfo';

// Stores
import { useStore } from '@Utils/hooks';

// Utils
import { compose } from '@Utils';
import { formatDate, formatPhoneNumber } from '@Utils/formatting';
import {
  CREATE_INVITATIONS,
  RESEND_INVITATIONS
} from '@Utils/constans/permissions';

const ParticipantCard = ({ match }) => {
  const { t } = useTranslation();
  const {
    AuthStore: { permissions },
    InvitationStore: {
      currentParticipant,
      setQuery,
      getParticipants,
      reset,
      participants,
      updateCurrentParticipant,
      resendInvitation,
      sendInvitation
    }
  } = useStore();

  const info = [
    {
      title: 'Participant information',
      contents: [
        {
          value: currentParticipant.role,
          title: 'Role'
        },
        {
          value: currentParticipant.cw_id,
          title: 'Care Wallet ID'
        }
      ]
    },
    {
      title: 'Activity',
      contents: [
        {
          value: formatDate(currentParticipant.status_updated_at),
          title: 'Status updated at'
        }
      ]
    }
  ];

  useEffect(() => {
    if (!Object.keys(currentParticipant).length) {
      setQuery(match.params.id);
      getParticipants().then(() => {
        const participant = participants.find(
          p => p.phone === `+${match.params.id}`
        );
        updateCurrentParticipant(participant);
      });
    }
    return () => reset();
  }, []);

  const buttonOptions = () => {
    if (
      permissions.includes(CREATE_INVITATIONS) &&
      currentParticipant.status === 'PREASSIGNED'
    ) {
      return {
        primaryBtnHandler: () => sendInvitation(currentParticipant.phone),
        primaryText: t('common.invite')
      };
    }

    if (
      permissions.includes(RESEND_INVITATIONS) &&
      (currentParticipant.status === 'INVITATION_PENDING' ||
        currentParticipant.status === 'INVITATION_EXPIRED')
    ) {
      return {
        primaryBtnHandler: () => resendInvitation(currentParticipant.phone),
        primaryText: t('common.resend')
      };
    }

    return {};
  };

  return (
    <Fragment>
      <Grid container>
        <PersonalInfo
          phone={formatPhoneNumber(currentParticipant.phone)}
          status={currentParticipant.status}
        />
        <GeneralInfo info={info} />
      </Grid>
      <ButtonsSection {...buttonOptions()} />
    </Fragment>
  );
};

ParticipantCard.propTypes = {
  match: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(ParticipantCard);
