/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper } from '@CommonScene';
import RoleName from '@RolesScene/scenes/common/RoleName';
import RoleSettings from '@RolesScene/scenes/common/RoleSettings';
import TermsAndConditionsList from '@RolesScene/scenes/EditRole/components/TermsAndConditionsList';
import CardsAccess from '@RolesScene/scenes/common/CardsAccess/container';
import SubmitSection from '@RolesScene/scenes/common/SubmitSection';
import BlockWrapper from '@CommonScene/BlockWrapper';

// Utils
import { compose } from '@Utils';
import { getRolesPath } from '@Utils/constans/paths';

class EditRole extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    RoleStore: PropTypes.object.isRequired,
    InvitationStore: PropTypes.object.isRequired
  };

  state = {
    selectedPermissions: ''
  };

  componentDidMount() {
    const {
      RoleStore: { getRole, setEditFormFields, setSelectedPermissionFromRole }
    } = this.props;

    getRole(this.getCurrentRoleId())
      .then(setEditFormFields)
      .then(setSelectedPermissionFromRole)
      .then(permissions =>
        this.setState({ selectedPermissions: JSON.stringify(permissions) })
      );
  }

  componentWillUnmount() {
    const {
      RoleStore: { resetCreationRoleData, resetRole }
    } = this.props;

    resetRole();
    resetCreationRoleData();
  }

  getCurrentRoleId() {
    return this.props.match.params.role;
  }

  goToRoles = () => this.props.history.push(getRolesPath.userRoles());

  handleSave = () => {
    this.props.RoleStore.updatePermissionsForRole(this.getCurrentRoleId()).then(
      this.goToRoles
    );
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.roles.roleDetails')
    };
  }

  render() {
    const {
      t,
      RoleStore: { selectedPermissions }
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <BlockWrapper>
          <RoleName disabled hideStepper title={t('mwAdmin.roles.roleName')} />
          <RoleSettings
            disabled
            hideStepper
            title={t('mwAdmin.roles.accessType')}
          />
          <TermsAndConditionsList disabled roleId={this.getCurrentRoleId()} />
          <CardsAccess
            hideStepper
            title={t('mwAdmin.roles.assignedCareCards')}
          />
          <SubmitSection
            isPrimaryBtnDisabled={
              JSON.stringify(selectedPermissions) ===
              this.state.selectedPermissions
            }
            primaryBtnHandler={this.handleSave}
            secondaryBtnHandler={this.goToRoles}
            primaryText={t('common.saveChanges')}
          />
        </BlockWrapper>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('RoleStore', 'InvitationStore'),
  observer
)(EditRole);
