/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RoleList from '@RolesScene/scenes/RolesList/container';

// Utils
import { useStore } from '@Utils/hooks';
import { userRolesTableConfig } from '@Assets/config/tables/roles';

// Constants
import { CREATE_USER_ROLES } from '@Utils/constans/permissions';
import { getRolesPath } from '@Utils/constans/paths';

const UserRolesList = ({ history }) => {
  const { UserRolesStore, AuthStore } = useStore();
  const { t } = useTranslation();
  const { permissions } = AuthStore;

  const getHeaderOptions = () => ({
    title: t('mwAdmin.sidebar.participantRoles'),
    placeholder: t('mwAdmin.roles.searchByRole')
  });

  const getButtonOptions = () => ({
    isShown: permissions.includes(CREATE_USER_ROLES),
    text: t('mwAdmin.roles.createRole'),
    onClickHandler: () => {
      history.push(getRolesPath.userRolesCreate());
    }
  });

  return (
    <RoleList
      headerOptions={getHeaderOptions()}
      buttonOptions={getButtonOptions()}
      RolesStore={UserRolesStore}
      tableConfig={userRolesTableConfig}
    />
  );
};

UserRolesList.propTypes = {
  history: PropTypes.object.isRequired
};

export default observer(UserRolesList);
