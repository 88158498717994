/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import {
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';

// Components
import Radio from '@CommonScene/materialUIComponents/Radio';
import StatusChip from '@CommonScene/materialUIComponents/StatusChip';

const useStyles = makeStyles(theme => ({
  hint: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: theme.spacing(2)
  },
  label: {
    fontSize: 14
  },
  published: {
    fontSize: 12,
    color: '#A095AE'
  }
}));

const RadioButton = ({ value, label, hint, published, status }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Grid container justify="space-between">
        <FormControlLabel
          value={`${value}`}
          control={<Radio />}
          label={label}
          classes={{ label: classes.label }}
        />
        {published && status ? (
          <div className={classes.flex}>
            <StatusChip text={status} />
            <span className={classes.published}>{`${t(
              'mwAdmin.roles.published'
            )} ${published}`}</span>
          </div>
        ) : null}
      </Grid>
      {hint ? (
        <Typography variant="caption" className={classes.hint}>
          {hint}
        </Typography>
      ) : null}
    </Grid>
  );
};

RadioButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  published: PropTypes.string,
  status: PropTypes.string
};

export default RadioButton;
