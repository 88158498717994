/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// Styles
import useStyles from './styles';

const CardAccessList = ({ resources }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={6}>
        <List
          subheader={
            <Typography variant="h6" className={classes.listHeader}>
              {t('mwAdmin.roles.cardsAccess')}:
            </Typography>
          }
          component={'ul'}
          className={classes.list}
        >
          {resources.map(resource => (
            <ListItem
              key={resource.id}
              component={'li'}
              className={classes.listItem}
            >
              <CheckBoxIcon className={classes.checkbox} />
              <Typography variant="body1" noWrap>
                {resource.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

CardAccessList.propTypes = {
  resources: PropTypes.array.isRequired
};

export default CardAccessList;
