/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import Editor from '@RolesScene/scenes/common/TermsAndConditions/Editor';
import Divider from '@RolesScene/scenes/common/Divider';
import SectionTitle from '@RolesScene/scenes/common/SectionTitle';
import { RadioButtons } from '@CommonScene';
import RadioButton from '@RolesScene/scenes/EditRole/components/RadioButton';
import VerticalStepper from '@RolesScene/scenes/common/VerticalStepper';

// Utils
import { useStore } from '@Utils/hooks';
import { compose } from '@Utils';
import { TERMS_REQUIREMENT } from '@Utils/constans';

export const TermsAndConditions = ({ roleId, disabled }) => {
  const { t } = useTranslation();
  const {
    TermsAndConditionsStore: {
      termsCheckbox,
      setCheckedTerms,
      isTermsRequired,
      getTermsAndConditionsList,
      clearTermsAndConditionsList,
      transformedTermsAndConditionsList,
      setActiveTermsCheckbox,
      getTermsAndConditionsItem,
      updateTermsAndConditionsText
    }
  } = useStore();

  useEffect(() => {
    getTermsAndConditionsList(roleId)
      .then(setActiveTermsCheckbox)
      .then(getTermsAndConditionsItem)
      .then(response => updateTermsAndConditionsText(response.text));
    return clearTermsAndConditionsList;
  }, []);

  const getButtons = () => {
    if (termsCheckbox === TERMS_REQUIREMENT.DISABLED) {
      return [
        {
          label: t('mwAdmin.roles.noTermsAndConditions'),
          value: TERMS_REQUIREMENT.DISABLED
        }
      ];
    }

    return [
      // TODO: uncomment when BE will have ability to update T&C
      // {
      //   label: t('mwAdmin.roles.addTermsAndConditions'),
      //   value: TERMS_REQUIREMENT.ENABLED
      // },
      ...transformedTermsAndConditionsList
    ];
  };

  return (
    <VerticalStepper step={3} hideStepper>
      <SectionTitle>{t('mwAdmin.roles.termsAndConditions')}</SectionTitle>
      <Grid container>
        <Grid item xs={6}>
          <RadioButtons
            disabled={disabled}
            component={RadioButton}
            activeValue={`${termsCheckbox}`}
            handleChange={setCheckedTerms}
            buttons={getButtons()}
          />
        </Grid>
        {isTermsRequired ? (
          <Grid item xs={6}>
            <Editor disabled={disabled} />
          </Grid>
        ) : null}
      </Grid>
      <Divider />
    </VerticalStepper>
  );
};

TermsAndConditions.propTypes = {
  disabled: PropTypes.bool,
  roleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default compose(observer)(TermsAndConditions);
