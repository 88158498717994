/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const setBulletBackgroundStyle = color => {
  return {
    '&:after': {
      background: `${color}!important`
    }
  };
};

const styles = theme => ({
  wrapper: {
    color: '#2F0A6B'
  },
  heading: {
    margin: `${theme.spacing(4)}px ${theme.spacing(4)}px 0 ${theme.spacing(
      4
    )}px`,
    paddingBottom: theme.spacing(),
    borderBottom: '1px solid #E9E2F4',
    position: 'relative',
    '&::first-letter': {
      textTransform: 'uppercase'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      background: 'lightgray',
      width: '16px',
      height: '16px',
      right: 0,
      top: 0,
      borderRadius: '8px'
    }
  },
  list: {
    maxHeight: '220px', // 5 items
    minHeight: '90px', // 2 items
    overflowY: 'scroll',
    padding: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& > div': {
      padding: `12px ${theme.spacing(4)}px`,
      justifyContent: 'space-between',
      '& p': {
        margin: 0
      }
    },
    '& > div:hover': {
      backgroundColor: '#F1F2F4'
    }
  },
  listItemText: {
    paddingRight: '10px'
  },
  listItemValue: {
    paddingLeft: '10px'
  },
  footer: {
    cursor: 'pointer',
    width: 'auto',
    margin: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(
      4
    )}px`,
    paddingTop: theme.spacing(),
    borderTop: '1px solid #E9E2F4',
    '& p': {
      margin: 0
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  ASSIGNED_WALLET: setBulletBackgroundStyle('#6FC960'),
  INVITATION_PENDING: setBulletBackgroundStyle('#FFA91E'),
  CREATED: setBulletBackgroundStyle('#9B51E0'),
  SUSPENDED: setBulletBackgroundStyle('#FE4848'),
  INVITATION_EXPIRED: setBulletBackgroundStyle('#FE4848')
});

export default styles;
