/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const setBulletBackgroundStyle = color => {
  return {
    '&:before': {
      background: `${color}!important`
    }
  };
};

const styles = () => ({
  chartWrapper: {
    position: 'relative'
  },
  totalCountWrapper: {
    position: 'absolute',
    width: '175px',
    textAlign: 'center',
    fontWeight: 'bold',
    top: 64,
    '& span': {
      color: '#2F0A6B',
      fontSize: '20px'
    },
    '& p': {
      color: '#A095AE',
      margin: '0'
    }
  },
  legend: {
    height: '100%',
    '& p::first-letter': {
      textTransform: 'uppercase'
    },
    '& > div': {
      position: 'relative',
      padding: '0 16px 0 32px',
      fontWeight: 'bold',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '16px',
        height: '16px',
        left: 0,
        top: 4,
        borderRadius: '8px'
      },
      '& span': {
        color: '#2F0A6B'
      },
      '& p': {
        margin: '0',
        color: '#A095AE'
      }
    }
  },
  ASSIGNED_WALLET: setBulletBackgroundStyle('#6FC960'),
  INVITATION_PENDING: setBulletBackgroundStyle('#FFA91E'),
  CREATED: setBulletBackgroundStyle('#9B51E0'),
  SUSPENDED: setBulletBackgroundStyle('#FE4848'),
  INVITATION_EXPIRED: setBulletBackgroundStyle('#FE4848')
});

export default styles;
