/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';

// Material UI
import { Grid, List, ListItem, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import shortid from 'shortid';
import { addSpacesInNumber } from '@Utils/formatting';
import qs from 'query-string';
import { translation } from '@Utils/translation';

// Styles
import styles from './styles';

const Card = ({ classes, history, status, items }) => {
  const total = items.reduce((acc, val) => Number(val.total) + acc, 0);

  // TODO: Temporary solution! Statuses with the same meaning on the backend has different names
  const statusDictionary = {
    CREATED: 'PREASSIGNED',
    ASSIGNED_WALLET: 'ACTIVE'
  };

  return (
    <Grid item md={3} className={classes.wrapper}>
      <BlockWrapper skipPadding>
        <p
          className={classnames(classes.bold, classes.heading, classes[status])}
        >
          {translation(status, 'statuses')}
        </p>
        <List component="ul" className={classes.list}>
          {items.map(i => {
            return (
              <ListItem
                key={shortid.generate()}
                button
                onClick={() => {
                  history.push({
                    pathname: '/participants',
                    search: qs.stringify({
                      role: i.role,
                      status: statusDictionary[status] || status
                    })
                  });
                }}
              >
                <p className={classes.listItemText}>{i.role}</p>
                <p>{addSpacesInNumber(i.total)}</p>
              </ListItem>
            );
          })}
        </List>
        <Grid
          container
          justify="space-between"
          className={classnames(classes.bold, classes.footer)}
          onClick={() => {
            history.push({
              pathname: '/participants',
              search: qs.stringify({
                status: statusDictionary[status] || status
              })
            });
          }}
        >
          <p>Total</p>
          <p>{addSpacesInNumber(total)}</p>
        </Grid>
      </BlockWrapper>
    </Grid>
  );
};

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default compose(
  withStyles(styles),
  withRouter,
  observer
)(Card);
