/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, toJS } from 'mobx';

// Providers
import { DepositsProvider } from '@Providers';

// stores
import withBaseDepositsMethods from '@Stores/withBaseDepositsMethods';
import BasePaymentsStore from '@Stores/BasePaymentsStore';
import CommonStore from '@Stores/CommonStore';
import AuthStore from '@Stores/AuthStore';

class DepositsStore extends withBaseDepositsMethods(BasePaymentsStore) {
  DATE_RANGE = 'DepositsDateRange';

  @observable balance = null;

  @observable minBalanceThreshold = null;

  @observable transactions = [];

  @observable transactionsSummary = {};

  @observable query = '';

  @observable orderBy = 'order_date';

  @observable lastPage = true;

  @action('depositsStore => getDepositsTransactions')
  getDepositsTransactions = bodyParams => {
    CommonStore.setPending();

    let requestsBodyParams = bodyParams || {};

    const requestsQueryParams = {
      sort: `${this.orderBy},${this.order}`
    };
    const statuses = toJS(this.selectedStatuses);

    if (this.query) {
      requestsBodyParams = { ...requestsBodyParams, sender: this.query };
    }

    if (statuses.length) {
      requestsBodyParams.statuses = statuses;
    }

    return DepositsProvider.getDepositsTransactions(
      AuthStore.careWalletId,
      requestsBodyParams,
      {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        ...requestsQueryParams
      }
    )
      .then(this.setDepositsTransactions)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('depositsStore => set transactions for wallet_id')
  setDepositsTransactions = response => {
    this.transactions = response.content;
  };

  @action('depositsStore => getTransactionsSummary')
  getTransactionsSummary = bodyParams => {
    CommonStore.setPending();

    const requestsBodyParams = bodyParams;

    return DepositsProvider.getTotalDepositsTransactions(
      AuthStore.careWalletId,
      requestsBodyParams
    )
      .then(this.setTransactionsSummary)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('depositsStore => set transactions summary for wallet_id')
  setTransactionsSummary = response => {
    this.transactionsSummary = response;
  };

  @action('depositsStore => _resetTransactions') _resetTransactions = () => {
    this.transactions.replace([]);
  };

  @action('depositsStore => filterByStatus') filterByStatus = () => {
    const bodyParams = {};
    const statuses = toJS(this.selectedStatuses);

    if (statuses.length) {
      bodyParams.statuses = statuses;
    }

    this.getTransactionsSummary(bodyParams);
    this.getDepositsTransactions(bodyParams);
  };
}

export default new DepositsStore();
