/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  withStyles
} from '@material-ui/core';

// Components
import { FromToDateFilter } from '@CommonScene';

// Styles
import styles from './styles';

export const PaymentsFilter = ({
  classes,
  selectName,
  selectList,
  selectValue,
  handleChangeSelect,
  handleChangeDate,
  dateRange
}) => (
  <Grid container className={classes.filterContainer}>
    <FormControl className={classes.marginRight}>
      <InputLabel id="sevice-name">{selectName}</InputLabel>
      <Select
        id="select-component"
        value={selectValue}
        onChange={handleChangeSelect}
        inputProps={{
          name: 'service',
          id: 'service'
        }}
      >
        {selectList.map(item => (
          <MenuItem id="menu-item" key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <FromToDateFilter
      id="date-filter"
      handleChangeDate={handleChangeDate}
      dateRange={{ from: dateRange.from_date, to: dateRange.to_date }}
    />
  </Grid>
);

PaymentsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  selectList: PropTypes.array,
  selectValue: PropTypes.string.isRequired,
  selectName: PropTypes.string.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  dateRange: PropTypes.object
};

PaymentsFilter.defaultProps = {
  dateRange: {},
  selectList: []
};

export default withStyles(styles)(PaymentsFilter);
