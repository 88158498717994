/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material UI
import { Button, Dialog, DialogActions, withStyles } from '@material-ui/core';

// Styles
import styles from './styles';

const ModalConfirmationSnippet = ({
  classes,
  isOpen,
  children,
  handleConfirmAction,
  handleCancelAction,
  confirmBtn,
  cancelBtn,
  borderColor
}) => (
  <Dialog
    id="dialog-window"
    classes={{
      paper: classNames(
        { [classes.border]: borderColor },
        { [classes[borderColor]]: borderColor }
      )
    }}
    BackdropProps={{
      classes: { root: classes.backDrop }
    }}
    open={isOpen}
    onClose={handleCancelAction}
    aria-labelledby="form-dialog-title"
  >
    <div id="form-dialog-title" className={classes.DialogText}>
      {children}
    </div>
    <DialogActions>
      {cancelBtn ? (
        <Button
          id="cancel-btn"
          className={classes.button}
          onClick={handleCancelAction}
        >
          {cancelBtn}
        </Button>
      ) : null}
      {confirmBtn ? (
        <Button
          id="confirm-btn"
          className={classNames(classes.button, classes.submitButton)}
          onClick={handleConfirmAction}
        >
          {confirmBtn}
        </Button>
      ) : null}
    </DialogActions>
  </Dialog>
);

ModalConfirmationSnippet.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  confirmBtn: PropTypes.string,
  cancelBtn: PropTypes.string,
  handleConfirmAction: PropTypes.func,
  handleCancelAction: PropTypes.func,
  children: PropTypes.any,
  borderColor: PropTypes.string
};

export default withStyles(styles)(ModalConfirmationSnippet);
