/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { addSpacesInNumber } from '@Utils/formatting';
import shortid from 'shortid';
import { translation } from '@Utils/translation';

// Styles
import styles from './styles';

const SummaryBlock = ({ classes, totalLabel, totalValueKey, items }) => {
  const total = items.reduce((acc, val) => Number(val[totalValueKey]) + acc, 0);

  return (
    <BlockWrapper skipPadding withBottomMargin>
      <Grid container className={classes.wrapper}>
        <Grid item md={2} className={classes.totalBlock}>
          <p>{addSpacesInNumber(total)}</p>
          <span>{totalLabel}</span>
        </Grid>
        {items.map(item => (
          <Grid key={shortid.generate()} md={2} item>
            <p>{addSpacesInNumber(item && item.total)}</p>
            <span>{translation(item && item.status, 'nodeStatuses')}</span>
          </Grid>
        ))}
      </Grid>
    </BlockWrapper>
  );
};

SummaryBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  totalLabel: PropTypes.string,
  totalValueKey: PropTypes.string,
  items: PropTypes.array.isRequired
};

SummaryBlock.defaultProps = {
  totalLabel: 'Total',
  totalValueKey: 'total'
};

export default compose(
  withStyles(styles),
  observer
)(SummaryBlock);
