/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// Material UI
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import TypeFilter from '@GroupsScene/scenes/GroupsList/components/List/TypeFilter';

// Stores
import GroupStore from '@Stores/GroupStore';

// utils
import { handleRowClick } from '@Utils';

export const fetchGroupBalance = ({ rowId }) => {
  GroupStore.fetchGroupBalance(rowId);
};

export const tableConfig = {
  defaultOrderBy: 'name',
  hasActions: true,
  handleRowClick: handleRowClick('/groups/view/'),
  rowIdKey: 'id',
  headings: [
    {
      id: 'name',
      title: i18n.t('common.name'),
      sortable: true,
      cb: GroupStore.setOrder
    },
    <TypeFilter
      key={shortid.generate()}
      items={[{ id: 'NPI', name: 'NPI' }, { id: 'TIN', name: 'TIN' }]}
    />,
    'ID'
  ],
  rowCells: ['name', 'custom', 'customIdField'],
  actionsConfig: {
    menuItems: [
      {
        name: i18n.t('common.remove'),
        icon: <DeleteIcon />,
        handleClick: fetchGroupBalance
      }
    ]
  }
};
