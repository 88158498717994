/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';
import CodeInput from '@AuthScene/scenes/ResetPassword/components/CodeInput';
import ResendCode from '@AuthScene/scenes/ResetPassword/components/ResendCode';

// Utils
import { displayPhone } from '@Utils/display';
import { compose } from '@Utils';

export class ResetPasswordCodeForm extends Component {
  static propTypes = {
    AuthStore: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      AuthStore: { clearInputFields }
    } = this.props;

    clearInputFields('resetPasswordCode', ['code']);
  }

  handleResendCode = e => {
    e.preventDefault();

    const {
      AuthStore: { clearErrorMessage, sendPhoneForResetPassword }
    } = this.props;

    clearErrorMessage();
    sendPhoneForResetPassword();
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const {
      AuthStore: { handleChangeForm }
    } = this.props;

    handleChangeForm(3);
  };

  backHandler = () => this.props.AuthStore.handleChangeForm(1);

  render() {
    const {
      AuthStore: {
        errors,
        sendPhoneForResetPassword,
        resetPasswordPhone,
        setVerificationCode,
        clearVerificationCode,
        verificationCode
      },
      t
    } = this.props;

    return (
      <form onSubmit={this.handleSubmitForm}>
        <HeaderTitle>{t('auth.resetPassword.stage2.heading')}</HeaderTitle>

        <CodeInput
          setCode={setVerificationCode}
          clearCode={clearVerificationCode}
        />

        <ResendCode
          onClick={sendPhoneForResetPassword}
          phone={displayPhone(resetPasswordPhone)}
        />

        <ErrorSection errors={errors} />

        <ResetPasswordButtons
          backBtnText={t('common.back')}
          nextBtnText={t('common.next')}
          backBtnOnclickHandler={this.backHandler}
          primaryBtnDisabled={!verificationCode}
        />
      </form>
    );
  }
}

export default compose(
  withTranslation(),
  inject('AuthStore'),
  observer
)(ResetPasswordCodeForm);
