/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper, CustomTooltip } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import shortid from 'shortid';
import { addSpacesInNumber } from '@Utils/formatting';
import classnames from 'classnames';
import { translation } from '@Utils/translation';

// Styles
import styles from './styles';

const Card = ({ classes, title, nodeItems }) => {
  const total = nodeItems.reduce((acc, val) => Number(val.total) + acc, 0);

  return (
    <Grid key={shortid.generate()} md={3} item className={classes.wrapper}>
      <BlockWrapper>
        <CustomTooltip label={title}>
          <p className={classnames(classes.bold, classes.cardName)}>{title}</p>
        </CustomTooltip>
        <Grid className={classes.listWrapper}>
          {nodeItems.map(nodeItem => (
            <Grid
              key={shortid.generate()}
              container
              justify="space-between"
              className={classes.listItem}
            >
              <p>{translation(nodeItem.status, 'nodeStatuses')}</p>
              <p>{addSpacesInNumber(nodeItem.total)}</p>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          justify="space-between"
          className={classnames(classes.bold, classes.footer)}
        >
          <p>Total</p>
          <p>{addSpacesInNumber(total)}</p>
        </Grid>
      </BlockWrapper>
    </Grid>
  );
};

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  nodeItems: PropTypes.array.isRequired
};

export default compose(
  withStyles(styles),
  observer
)(Card);
