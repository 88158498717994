/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material-UI imports
import {
  Button,
  Input,
  InputLabel,
  FormControl,
  Paper,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Usable components
import { TextField } from '@CommonScene';

// Utils and helpers
import classNames from 'classnames';
import { compose } from '@Utils';
import { formatDate } from '@Utils/formatting';

// Component Style imports
import styles from './styles';

export class NetworkProfileEditForm extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    CommonStore: PropTypes.object,
    NetworkStore: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }),
    history: PropTypes.shape({
      replace: PropTypes.func,
      goBack: PropTypes.func
    }),
    classes: PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const {
      NetworkStore: {
        forms: {
          networkEditForm: {
            fields: {
              name: { value: newName },
              surname: { value: newSurname }
            }
          }
        }
      }
    } = props;

    if (state.name === null && state.surname === null) {
      return {
        name: newName,
        surname: newSurname
      };
    }

    return null;
  }

  state = {
    name: null,
    surname: null
  };

  componentDidMount() {
    this.props.NetworkStore.getUser(this.getCurrentUserId());
  }

  componentWillUnmount() {
    this.props.NetworkStore.resetCurrentUser();
  }

  getCurrentUserId() {
    return this.props.match.params.id;
  }

  handleSubmitUserInfo = e => {
    e.preventDefault();
    const {
      history,
      NetworkStore: {
        handleChangeUserName,
        handleChangeUserSurname,
        updateUser,
        resetForms,
        forms: {
          networkEditForm: {
            fields: {
              name: { value: name },
              surname: { value: surname }
            }
          }
        }
      }
    } = this.props;

    handleChangeUserName(name);
    handleChangeUserSurname(surname);

    updateUser(this.getCurrentUserId()).then(() =>
      history.replace(`/networks/view/${this.getCurrentUserId()}`)
    );

    resetForms();
  };

  handleGoBack = () => {
    const {
      history,
      CommonStore: { delModalOptions }
    } = this.props;

    delModalOptions();
    history.goBack();
  };

  handleProfileActionClick = () => {
    this.props.CommonStore.setModalOptions({
      modalName: 'ConfirmationAction',
      modalProps: { handleConfirmAction: this.handleGoBack }
    });
  };

  formatUserStatus = str => {
    let result = str.toLowerCase();
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  };

  onFieldChange = e =>
    this.props.NetworkStore.onFieldChange(
      e.target.name,
      e.target.value,
      'networkEditForm'
    );

  isSaveBtnDisabled = () => {
    const {
      NetworkStore: {
        forms: {
          networkEditForm: {
            meta: { isValid },
            fields: {
              name: { value: newName },
              surname: { value: newSurname }
            }
          }
        }
      }
    } = this.props;
    const { name, surname } = this.state;

    return !isValid || (name === newName && surname === newSurname);
  };

  render() {
    const { classes, t } = this.props;
    const { forms, currentUser } = this.props.NetworkStore;

    return (
      <React.Fragment>
        <Grid container className={classes.padding}>
          <Grid item xs={3}>
            <Paper className={classes.paper} elevation={0}>
              <TextField
                name="name"
                label={t('common.name')}
                inputProps={{ maxLength: 40 }}
                value={forms.networkEditForm.fields.name.value}
                error={forms.networkEditForm.fields.name.error}
                onChange={this.onFieldChange}
                placeholder={t('mwAdmin.networkParticipants.enterYourName')}
              />
            </Paper>
            <Paper className={classes.paper} elevation={0}>
              <TextField
                name="surname"
                label={t('common.surname')}
                inputProps={{ maxLength: 40 }}
                value={forms.networkEditForm.fields.surname.value}
                error={forms.networkEditForm.fields.surname.error}
                onChange={this.onFieldChange}
                placeholder={t('mwAdmin.networkParticipants.enterYourSurname')}
              />
            </Paper>
            <Paper className={classes.paper} elevation={0}>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="component-disabled">
                  {t('common.cellPhone')}
                </InputLabel>
                <Input
                  id="component-disabled"
                  value={currentUser.phone_number || ''}
                />
              </FormControl>
            </Paper>
            <Paper className={classes.paper} elevation={0}>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="component-disabled">
                  {t('common.careWalletId')}
                </InputLabel>
                <Input
                  id="component-disabled"
                  value={currentUser.care_wallet_id || ''}
                />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper className={classes.paper} elevation={0}>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="component-disabled">
                  {t('common.status')}
                </InputLabel>
                <Input
                  id="component-disabled"
                  className={classes[currentUser.status]}
                  value={
                    this.formatUserStatus(`${currentUser.status}`) || 'None'
                  }
                />
              </FormControl>
            </Paper>
            <Paper className={classes.paper} elevation={0}>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="component-disabled">
                  {t('mwAdmin.networkParticipants.joinedNetwork')}
                </InputLabel>
                <Input
                  id="component-disabled"
                  value={
                    currentUser.created_at
                      ? formatDate(currentUser.created_at)
                      : 'None'
                  }
                />
              </FormControl>
            </Paper>
            <Paper className={classes.paper} elevation={0}>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="component-disabled">
                  {t('mwAdmin.networkParticipants.lastUpdated')}
                </InputLabel>
                <Input
                  id="component-disabled"
                  value={
                    currentUser.updated_at
                      ? formatDate(currentUser.updated_at)
                      : 'None'
                  }
                />
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          container
          className={classNames(classes.padding, classes.bottomSpace)}
          justify="flex-end"
        >
          <Button
            onClick={this.handleProfileActionClick}
            className={classes.button}
          >
            {t('common.cancel')}
          </Button>
          <Button
            className={classes.button}
            disabled={this.isSaveBtnDisabled()}
            variant="contained"
            color="secondary"
            onClick={this.handleSubmitUserInfo}
          >
            {t('common.save')}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('CommonStore', 'NetworkStore'),
  withRouter,
  observer
)(NetworkProfileEditForm);
