/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, toJS } from 'mobx';

// Providers
import { WithdrawalProvider } from '@Providers';

// Stores
import withBaseDepositsMethods from '@Stores/withBaseDepositsMethods';
import CommonStore from '@Stores/CommonStore';
import BasePaymentsStore from '@Stores/BasePaymentsStore';

// Config
import { WithdrawalsForms as forms } from '@Assets/config/forms/withdrawals';

class WithdrawalStore extends withBaseDepositsMethods(BasePaymentsStore) {
  DATE_RANGE = 'WithdrawalDateRange';

  @observable forms = { ...forms };

  @observable balance = null;

  @observable minBalanceThreshold = null;

  @observable withdrawalTickets = [];

  @observable transactionsSummary = {};

  @observable query = '';

  @observable orderBy = 'order_date';

  @observable lastPage = true;

  @observable isWithdrawalTicketCreated = false;

  @action('withdrawalStore => setWithdrawalTickets')
  setWithdrawalTickets = data => {
    this.withdrawalTickets = data;
  };

  @action('withdrawalStore => getWithdrawalTickets')
  getWithdrawalTickets = bodyParams => {
    CommonStore.setPending();

    let requestsBodyParams = bodyParams || {};

    const statuses = toJS(this.selectedStatuses);

    if (this.query) {
      requestsBodyParams = { ...requestsBodyParams, sender: this.query };
    }

    if (statuses.length) {
      requestsBodyParams.statuses = statuses;
    }

    // TODO: implement when API will be ready

    // TODO: remove it when API will be ready
    this.setWithdrawalTickets([]);
  };

  createWithdrawalTicket = data => {
    CommonStore.setPending();
    return WithdrawalProvider.createWithdrawalTicket(data)
      .then(this.handleCreateWithdrawalTicket)
      .catch(
        action(err => {
          if (err.status === 400) {
            const errorText = err.response.body.errors.reduce((acc, error) => {
              let currentErrorText = acc;
              if (err.response.body.errors.length > 1) {
                currentErrorText += `${error.default_message}; `;
              } else {
                currentErrorText += error.default_message;
              }
              return currentErrorText;
            }, '');
            CommonStore.setModalOptions({
              modalName: 'ModalError',
              modalProps: { text: errorText }
            });
          }
          this._handleErrors(err);
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action('withdrawalStore => handleCreateWithdrawalTicket')
  handleCreateWithdrawalTicket = () => {
    this.isWithdrawalTicketCreated = true;
  };

  @action('withdrawalStore => clearCreatedWithdrawalTicket')
  clearCreatedWithdrawalTicket = () => {
    this.isWithdrawalTicketCreated = false;
  };

  @action('withdrawalStore => clearFormData')
  clearFormData = () => {
    this.forms = { ...forms };
  };
}

export default new WithdrawalStore();
