/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import { MainContentWrapper } from '@CommonScene';
import RoleSettings from '@RolesScene/scenes/common/RoleSettings';
import BlockWrapper from '@CommonScene/BlockWrapper';
import RoleName from '@RolesScene/scenes/common/RoleName';
import TermsAndConditions from '@RolesScene/scenes/AddRole/components/TermsAndConditionsOption';
import CardsAccess from '@RolesScene/scenes/common/CardsAccess/container';
import SubmitSection from '@RolesScene/scenes/common/SubmitSection';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';
import { getRolesPath } from '@Utils/constans/paths';
import InfoIcon from '@CommonScene/modals/common/InfoIcon';

const AddRole = ({ history }) => {
  const { t } = useTranslation();

  const headerOptions = {
    title: t('mwAdmin.roles.newRole')
  };

  const {
    TermsAndConditionsStore: { isTermsRequired, draftText },
    RoleStore: {
      createRole,
      forms,
      resetCreationRoleData,
      selectedPermissions
    },
    CommonStore: { setModalOptions }
  } = useStore();

  useEffect(() => {
    return () => resetCreationRoleData();
  }, []);

  const isPrimaryBtnDisabled = () => {
    if (isTermsRequired) {
      return !(
        forms.roleSettings.fields['role name'].value &&
        selectedPermissions.length &&
        draftText
      );
    }

    return !(
      forms.roleSettings.fields['role name'].value && selectedPermissions.length
    );
  };

  const secondaryBtnHandler = useCallback(() => {
    if (
      forms.roleSettings.fields['role name'].value ||
      selectedPermissions.length ||
      draftText
    ) {
      setModalOptions({
        modalName: 'ModalWarning',
        modalProps: {
          title: t('modals.confirmCancellation'),
          text: t('modals.discardChanges'),
          Icon: InfoIcon,
          borderColor: 'yellowBorder',
          handleConfirmAction: history.push.bind(
            this,
            getRolesPath.userRoles()
          ),
          confirmBtn: t('common.submit')
        }
      });
      return;
    }

    history.push(getRolesPath.userRoles());
  });

  const primaryBtnHandler = useCallback(() =>
    createRole().then(() => {
      history.push(getRolesPath.userRoles());
      resetCreationRoleData();
    })
  );

  return (
    <MainContentWrapper headerOptions={headerOptions}>
      <BlockWrapper>
        <RoleName title={t('mwAdmin.roles.addRoleName')} />
        <RoleSettings title={t('mwAdmin.roles.chooseAccessType')} />
        <TermsAndConditions />
        <CardsAccess title={t('mwAdmin.roles.assignCareCards')} />
        <SubmitSection
          isPrimaryBtnDisabled={isPrimaryBtnDisabled()}
          primaryBtnHandler={primaryBtnHandler}
          secondaryBtnHandler={secondaryBtnHandler}
        />
      </BlockWrapper>
    </MainContentWrapper>
  );
};

AddRole.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(observer)(AddRole);
