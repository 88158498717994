/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';

// Utils
import { translation } from '@Utils/translation';
import { statusMapping } from '@Assets/config/statusMapping';
import StatusChip from '@CommonScene/materialUIComponents/StatusChip';

export const formatPhoneNumber = phoneNumberString => {
  if (phoneNumberString) {
    const cleaned = phoneNumberString.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return `${intlCode}${match[2]}-${match[3]}-${match[4]}`;
    }
    return phoneNumberString;
  }
  return '';
};

export const renderUserRolesName = arr => {
  const newArr = _.filter(arr, n => _.isObject(n));
  const uniqRolesTemplates = _.uniqBy(newArr, 'role_template_id');
  return _.map(uniqRolesTemplates, (n, i) => {
    const divider = i === uniqRolesTemplates.length - 1 ? '' : '; ';
    return (
      <Fragment key={i}>
        {`${translation(n.name, 'rolesNames')}${divider}`}
        <br />
      </Fragment>
    );
  });
};

export const formatUserStatus = str => {
  const status = statusMapping[str] || {
    title: str.toLowerCase().replace(/_/g, ' '),
    color: 'black'
  };

  return (
    <div style={{ color: status.color, testTransform: 'capitalize' }}>
      {status.title}
    </div>
  );
};

export const formatDate = (date, formatString = 'MM/DD/YYYY LT') =>
  date && moment(date).format(formatString);

export const renderCellContent = (cell, rowData) => {
  const getInnerPropValue = val =>
    // If dotted string for child object fields
    val.split('.').reduce((o, i) => o && o[i], rowData);

  if (typeof cell === 'string' && cell.includes('.')) {
    return getInnerPropValue(cell);
  }

  if (Array.isArray(cell)) {
    return cell.map(key => rowData[key]).join(' ');
  }

  if (typeof cell === 'object') {
    if (cell.cb) {
      return cell.cb(getInnerPropValue(cell.key));
    }

    if (cell.styles) {
      return <span style={cell.styles}>{rowData[cell.key]}</span>;
    }

    if (cell.translationKey) {
      return (
        <StatusChip
          text={rowData[cell.key]}
          translationKey={cell.translationKey}
        />
      );
    }
  }

  // TODO: Check responds from backend
  if (cell === 'status') {
    return <StatusChip text={rowData[cell]} />;
  }

  return rowData[cell];
};

export const getParsedCookies = () =>
  document.cookie.split(';').reduce((result, currentValue) => {
    const [key, val] = currentValue
      .trim()
      .split('=')
      .map(decodeURIComponent);
    try {
      return Object.assign(result, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(result, { [key]: val });
    }
  }, {});

export const parseTokens = () => {
  const parsedTokens = getParsedCookies();

  if (parsedTokens.token && parsedTokens.refreshToken) {
    return {
      token: parsedTokens.token,
      refreshToken: parsedTokens.refreshToken,
      expiredDate: parsedTokens.expiredDate,
      expiredTime: parsedTokens.expiredTime
    };
  }

  const [token, refreshToken] = Object.keys(parsedTokens)
    .filter(item => item.includes('token') || item.includes('refreshToken'))
    .reduce(
      (prevValue, curValue) => {
        if (curValue.includes('token')) {
          return [prevValue[0] + parsedTokens[curValue], prevValue[1]];
        }
        return [prevValue[0], prevValue[1] + parsedTokens[curValue]];
      },
      ['', '']
    );

  if (!token) {
    return {};
  }

  return {
    token,
    refreshToken,
    expiredDate: parsedTokens.expiredDate,
    expiredTime: parsedTokens.expiredTime
  };
};

export const getRoutes = routes => {
  return routes.filter(item => {
    const permissionNames = getParsedCookies().permissionNames;

    // TODO: bugfix - temporary solution
    if (item.roles && permissionNames) {
      // if (
      //   !roles.includes('SYSTEM_DECK') &&
      //   !roles.includes('PAYMENT_ADMIN_DECK') &&
      //   !roles.includes('CAN_SPONSOR_DECK')
      // ) {
      //   // set default role for 403 error
      //   roles.push('SYSTEM_DECK');
      // }

      return item.roles.some(role => permissionNames.includes(role));
    }

    return true;
  });
};

export const addCommasInNumber = n => {
  if (!n) {
    return '0';
  }
  return `${n.toLocaleString().split('.')[0]}.${n.toFixed(2).split('.')[1]}`;
};

export const addSpacesInNumber = n => {
  if (!n) {
    return '0';
  }
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const hideFieldsInfo = str =>
  str && `${str.substring(0, 4)}****${str.substring(str.length - 4)}`;

export const renderEtherscanLink = str => (
  <a
    href={`https://etherscan.io/address/${str}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {hideFieldsInfo(str)}
  </a>
);

export const getDateFilterKey = key => {
  const dateKeys = {
    from: 'from_date',
    to: 'to_date'
  };

  return dateKeys[key];
};

export const getMenuItems = route => route.section && route.menu;

export const getVersionWithDate = ({ version, date }) =>
  version ? `v${version}.${formatDate(date, 'MM/DD/YYYY')}` : '';

export const setItemsChecked = (items, preSelected) => {
  if (preSelected && preSelected.length) {
    return items.map(item => {
      if (item.name === preSelected) {
        return { name: item.name, checked: true };
      }
      if (preSelected.includes(item.name)) {
        return { name: item.name, checked: true };
      }
      return { name: item.name };
    });
  }
  return items.map(item => ({ name: item.name, checked: true }));
};
