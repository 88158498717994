/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';

// Component
import StatusChip from '@CommonScene/materialUIComponents/StatusChip';

// Image
import { ParticipantPortrait } from '@Utils/images';

// Styles
import useStyles from './styles';

const PersonalInfo = ({ name, surname, phone, status }) => {
  const classes = useStyles();

  return (
    <Grid item xs={4} classes={{ root: classes.root }}>
      <div className={classes.portrait}>
        <ParticipantPortrait />
      </div>
      {name && surname ? (
        <div className={classes.name}>{`${name} ${surname}`}</div>
      ) : null}
      <div className={classes.phone}>{phone}</div>
      <StatusChip text={status} />
    </Grid>
  );
};

PersonalInfo.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
  phone: PropTypes.string,
  status: PropTypes.string
};

export default PersonalInfo;
