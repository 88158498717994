// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { addSpacesInNumber } from '@Utils/formatting';
import { translation } from '@Utils/translation';

// Styles
import styles from './styles';

const InfoBlock = ({ classes, totalLabel, data }) => {
  return (
    <BlockWrapper skipPadding withBottomMargin>
      <Grid container className={classes.wrapper}>
        <Grid item md={2} className={classes.totalBlock}>
          <p>{addSpacesInNumber(data.total)}</p>
          <span>{totalLabel}</span>
        </Grid>
        <Grid md={2} item>
          <p>{data.type}</p>
          <span>Node type</span>
        </Grid>
        <Grid md={2} item>
          <p>{data.role}</p>
          <span>Assigned node</span>
        </Grid>
        <Grid md={2} item>
          <p>{translation(data.status, 'nodeStatuses')}</p>
          <span>Status</span>
        </Grid>
      </Grid>
    </BlockWrapper>
  );
};

InfoBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  totalLabel: PropTypes.string,
  data: PropTypes.object.isRequired
};

InfoBlock.defaultProps = {
  totalLabel: 'Total'
};

export default compose(
  withStyles(styles),
  observer
)(InfoBlock);
