/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';
import privateRoutes from '@Utils/constans/privateRoutes';
import { ArrowBackIcon } from '@Utils/constans/icons';

// Styles
import useStyles from './styles';

export const getPath = path => privateRoutes.find(route => route.name === path);

export const LinkBack = ({ history }) => {
  const {
    CommonStore: { currentPath, clearBackPath, backPath }
  } = useStore();
  const classes = useStyles();
  const { t } = useTranslation();

  const getBackPath = () => {
    const path = getPath(currentPath);

    if (!path || !path.parent) {
      return null;
    }

    const parentPath = getPath(path.parent);

    const match = matchPath(history.location.pathname, {
      path: path.path,
      exact: true,
      strict: false
    });

    if (!parentPath.getPath) {
      return { url: parentPath.path, text: parentPath.backLink };
    }

    return { url: parentPath.getPath(match.params), text: parentPath.backLink };
  };

  const [backLink, setBackLink] = useState(getBackPath);

  useEffect(() => {
    setBackLink(backPath);
  }, [backPath]);

  const route = backLink || getBackPath();

  const onClickHandler = useCallback(() => {
    history.push(route.url);
    clearBackPath();
  });

  const routeText = route && route.text ? route.text : t('common.back');

  return route ? (
    <div id="back-link" onClick={onClickHandler} className={classes.link}>
      <ArrowBackIcon className={classes.icon} />
      <span>{routeText}</span>
    </div>
  ) : null;
};

LinkBack.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  observer
)(LinkBack);
