/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import classNames from 'classnames';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { CustomTooltip } from '@CommonScene';

// Styles
import styles from './styles';

// Utils
import { compose } from '@Utils';

export class RowActions extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    extraId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    menuItems: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    extraId: undefined
  };

  handleItemClick(event, handler, isRedirect) {
    const { history, rowId, extraId } = this.props;
    event.stopPropagation();
    // extraId used for specific id for redirection
    handler({
      rowId: extraId || rowId,
      redirectTo: isRedirect ? history.push : null
    });
  }

  render() {
    const { classes, menuItems } = this.props;

    return (
      <Grid container justify="flex-end" className={classes.actionsWrapper}>
        {menuItems.length
          ? menuItems.map(item => (
              <CustomTooltip key={shortid.generate()} label={item.name}>
                <span
                  className={classNames(
                    classes.actionIconWrapper,
                    'action-item'
                  )}
                  onClick={e =>
                    this.handleItemClick(e, item.handleClick, item.isRedirect)
                  }
                >
                  {item.icon}
                </span>
              </CustomTooltip>
            ))
          : null}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter
)(RowActions);
